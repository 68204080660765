import axios from "axios";

axios.defaults.baseURL = "https://api.mychatbot.app/";

export const getPrice = async (data = {}) => {
  try {
    const params = new URLSearchParams(data);
    const r = await axios.get("token-price?" + params);
    return r.data;
  } catch ({ message }) {
    throw message;
  }
};

export const deleteUserAccountByID = async (account) => {
  try {
    const params = new URLSearchParams({ account });
    const r = await axios.get("delete-account?" + params);
    return r.data;
  } catch ({ message }) {
    throw message;
  }
};

export const getAFormOfPayment = async ({ account, amount = 1 } = {}) => {
  try {
    const params = new URLSearchParams({ account, amount });
    const r = await axios.get("wfplink?" + params);
    return r.data;
  } catch ({ message }) {
    throw message;
  }
};
