import { useState, useCallback } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchIntegrations,
  selectSelectedAssistant,
  selectSelectedIntegrationsIdsByType,
  selectSelectedIntegrationType,
} from "../../../../../../store/slices/integrationSlice";
import {
  useIntegrationTokenMutation,
  useValidateIntegrationAssetsMutation,
} from "../../../../../../store/api";
import useUpdateAssistantIntegrations from "../../../../useUpdateAssistantIntegrations";
import useUpdateIntegrationData from "../../../../useUpdateIntegrationData";
import { fetchInstructions as fetchAssistants } from "../../../../../../store/slices/assistantSlice";
import { useLoadingContext } from "../../../../useLoadingContext";
import { useIntegrationChecks } from "../useIntegrationChecks";
import { INTEGRATION_CONFIG } from "../../../../../../constants/integrations";

const useKeyConnectionData = () => {
  const { isLoading, setIsLoading } = useLoadingContext();

  const [integrationFields, setIntegrationFields] = useState({});

  const selectedAssistant = useSelector(selectSelectedAssistant);
  const user = useSelector((state) => state.auth.user);
  const { addAssistantIntegration, removeAssistantIntegration } =
    useUpdateAssistantIntegrations();
  const { deleteIntegration } = useUpdateIntegrationData();
  const selectedIntegrationType = useSelector(selectSelectedIntegrationType);
  const selectedIntegrationName = selectedIntegrationType?.name;
  const selectedIntegrationsIdsByType = useSelector(
    selectSelectedIntegrationsIdsByType
  );
  const [integrationToken] = useIntegrationTokenMutation();
  const [validateIntegrationAssets] = useValidateIntegrationAssetsMutation();
  const dispatch = useDispatch();
  const { isAuthTypeApiRefreshToken } = useIntegrationChecks();

  const getFieldsForIntegration = useCallback(
    (integrationName) => {
      return integrationFields[integrationName] || {};
    },
    [integrationFields]
  );

  const updateField = useCallback(
    (integrationName, fieldName, value) => {
      setIntegrationFields((prev) => ({
        ...prev,
        [integrationName]: {
          ...prev[integrationName],
          [fieldName]: value,
        },
      }));
    },
    [setIntegrationFields]
  );

  const resetFieldsForIntegration = useCallback(
    (integrationName) => {
      setIntegrationFields((prev) => ({
        ...prev,
        [integrationName]: {},
      }));
    },
    [setIntegrationFields]
  );

  const createConnection = useCallback(async () => {
    try {
      setIsLoading(true);

      const fields = getFieldsForIntegration(selectedIntegrationName);
      const config =
        INTEGRATION_CONFIG[selectedIntegrationName] ||
        INTEGRATION_CONFIG.default;

      // Checking if all required fields are filled
      const missingFields = config.fields.filter((field) => !fields[field]);
      if (missingFields.length > 0) {
        toast.error(
          `Please, fill all required fields: ${missingFields.join(", ")}`
        );
        return;
      }

      const requestData = {
        account_id: user.id,
        integration: selectedIntegrationName,
        ...config.mapFields(fields),
      };

      const integrationRes = await integrationToken(requestData);

      if (integrationRes.error) {
        toast.error(integrationRes.error);
        return;
      }

      const validateIntegrationRes = await validateIntegrationAssets(
        requestData
      );

      if (validateIntegrationRes.error) {
        await deleteIntegration(integrationRes.data.id);
        toast.error(validateIntegrationRes.error.data.error);
      } else {
        const currentAssistantIntegrationId =
          selectedAssistant?.integrations?.find((id) =>
            selectedIntegrationsIdsByType.includes(id)
          );

        let updatedIntegrations = null;

        if (currentAssistantIntegrationId) {
          updatedIntegrations = await removeAssistantIntegration(
            currentAssistantIntegrationId
          );
        }
        await addAssistantIntegration(
          integrationRes.data.id,
          updatedIntegrations
        );

        resetFieldsForIntegration(selectedIntegrationName);
      }
    } catch (error) {
      toast.error(error?.data?.error || error?.message || `Error: ${error}`);
    } finally {
      await dispatch(fetchIntegrations());
      await dispatch(fetchAssistants());
      setIsLoading(false);
    }
  }, [
    setIsLoading,
    getFieldsForIntegration,
    selectedIntegrationName,
    isAuthTypeApiRefreshToken,
    integrationToken,
    validateIntegrationAssets,
    selectedAssistant,
    selectedIntegrationsIdsByType,
    removeAssistantIntegration,
    addAssistantIntegration,
    deleteIntegration,
    resetFieldsForIntegration,
    dispatch,
    fetchIntegrations,
    fetchAssistants,
    user.id,
  ]);

  return {
    createConnection,
    isLoading,
    integrationFields,
    updateField,
    getFieldsForIntegration,
    resetFieldsForIntegration,
    setIntegrationFields,
  };
};

export default useKeyConnectionData;
