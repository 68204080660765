import styles from "./Integration.module.scss";
import { Loader } from "../Loader";
import React, { useCallback, useMemo, useState } from "react";
import { useEffect } from "react";
import { Modal } from "../Modal";
import { Button } from "../Button";
import { Input } from "../Input";
import {
  PLUGIN_NAME_REGEX,
  SITE_URL_REGEX,
} from "../../constants/validationScheme";
import supabase from "../../supabase";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { WORDPRESS_CMS, OPENCART_CMS } from "../../constants/knowledgeBaseItems";
import classNames from "classnames";
import { TOOL_TYPE } from "../../constants/integrations";
import { useValidateIntegrationAssetsMutation } from "../../store/api";

export const IntegrationWithKeys = (props) => {
  const {
    id,
    title,
    icon,
    description,
    connected,
    fetchIntegrations,
    isLoadingRefreshData,
    children,
    knowledgeBase,
    isAnyCMSConnected,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [domain, setDomain] = useState("");
  const [isDomainValid, setIsDomainValid] = useState(true);
  const [pluginName, setPluginName] = useState("feedv1");
  const [isPluginNameValid, setIsPluginNameValid] = useState(true);
  const [validateIntegrationAssets] = useValidateIntegrationAssetsMutation();
  const [inventoryName, setInventoryName] = useState("");

  const user = useSelector((state) => state.auth.user);

  const handleDomainChange = useCallback((e) => {
    setDomain(e.target.value);
    if (SITE_URL_REGEX.test(e.target.value)) {
      setIsDomainValid(true);
    } else {
      setIsDomainValid(false);
    }
  }, []);

  const handlePluginNameChange = useCallback((e) => {
    if (e.target.value.trim() === "") {
      setIsPluginNameValid(false);
      setPluginName(e.target.value);
    } else if (PLUGIN_NAME_REGEX.test(e.target.value)) {
      setPluginName(e.target.value.replaceAll(" ", "-"));
      setIsPluginNameValid(true);
    }
  }, []);

  const updateIntegrationAssets = async () => {
    const parsedDomain = new URL(domain);
    const domainHostname = parsedDomain.hostname;

    const updatedPluginName = pluginName.trim().toLowerCase();

    var access_token = `https://${domainHostname}/wp-json/mcb/v1/${user.id}-${updatedPluginName}`;
    if (inventoryName === "oc-inventory") {
      access_token = `https://${domainHostname}/index.php?route=extension/module/mychatbot/products&app_id=${updatedPluginName}`;
    } else if (inventoryName === "xml-inventory") {
      access_token = domain;
    }

    try {
      const account_id = user.id;
      await supabase
        .from("integrations")
        .insert({
          access_token,
          type: "cms",
          integration: title,
          knowledge_base: knowledgeBase,
          is_knowledge_base: true,
        })
        .match({ account_id: user.id });
      toast.success("Integration assets updated successfully");

      if (inventoryName !== "xml-inventory") {
        window.open(
          `${process.env.REACT_APP_API_URL}/downloads/${inventoryName}/${account_id}/${updatedPluginName}`,
        );
      } else {
        setIsModalOpen(false);
        const validateIntegrationRes = await validateIntegrationAssets({
          account_id: user.id,
          integration: title,
          access_token,
        });
        if (validateIntegrationRes.error) {
          toast.error(validateIntegrationRes.error);
        }
      }
    } catch ({ message }) {
      toast.error(message);
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  const handleOnConnect = useCallback(async () => {
    if (isDomainValid && isPluginNameValid) {
      setIsLoading(true);
      try {
        await updateIntegrationAssets();
      } catch ({ message }) {
        toast.error(message);
      }
      fetchIntegrations();
    }
  }, [
    fetchIntegrations,
    isDomainValid,
    isPluginNameValid,
    updateIntegrationAssets,
  ]);

  const handleOnRevoke = useCallback(async () => {
    setIsLoading(true);
    try {
      const { error: errorSupabase } = await supabase
        .from("integrations")
        .delete()
        .eq("account_id", user.id)
        .eq("id", id)
        .eq("integration", title);
      setDomain("");
      setPluginName("");
      setInventoryName("");
      if (errorSupabase) {
        toast.error(errorSupabase);
      }
    } catch ({ message }) {
      toast.error(message);
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
      fetchIntegrations();
    }
  }, [fetchIntegrations, id, title, user.id]);

  const isIntegrationDisabled = useMemo(() => {
    return isAnyCMSConnected && !connected;
  }, [connected, isAnyCMSConnected]);

  const integrationContent = useMemo(
    () => (
      <>
        <div className={styles.iconContainer}>
          <div className={styles.iconBox}>{icon ? icon : children}</div>
          {!!title && <div className={styles.title}>{title}</div>}
          {!!description && (
            <div className={styles.description}>{description}</div>
          )}
        </div>
        <Button
          onClick={() => {
            connected ? handleOnRevoke() : setIsModalOpen(true);
          }}
          className={classNames(styles.button, {
            [styles.connected]: connected,
          })}
          title={connected ? "revoke" : "connect"}
          disabled={isIntegrationDisabled}
        />
      </>
    ),
    [
      children,
      connected,
      description,
      handleOnRevoke,
      icon,
      isIntegrationDisabled,
      title,
    ],
  );

  const modalContent = useMemo(
    () => (
      <div className={styles.modalContent}>
        <div>
          <Input
            inputTitle={inventoryName && inventoryName === "xml-inventory" ? "XML file URL" : "Website URL"}
            customClass={styles.input}
            onChange={handleDomainChange}
            error={!isDomainValid}
            errorText={isDomainValid ? "" : "Invalid URL"}
            placeholder="https://example.com"
          />
        </div>
        { inventoryName && inventoryName !== "xml-inventory" && (
          <div>
            <Input
              inputTitle="Plugin Name (Alphanumeric characters and spaces/hyphens only)"
              customClass={styles.input}
              onChange={inventoryName !== "xml-inventory" ? handlePluginNameChange : () => {}}
              errorText={isPluginNameValid ? "" : "Plugin name is required"}
              placeholder="Plugin for My Site"
              value={pluginName}
            />
          </div>
        )}
      </div>
    ),
    [
      handleDomainChange,
      handlePluginNameChange,
      isDomainValid,
      isPluginNameValid,
      pluginName,
      inventoryName,
    ],
  );

  const modalActions = useMemo(() => {
    const isDisabled =
      !domain || !pluginName || !isDomainValid || !isPluginNameValid;
    return (
      <Button
        variant="contained"
        title="connect"
        onClick={handleOnConnect}
        className={styles.connectButton}
        disabled={isDisabled}
      />
    );
  }, [domain, handleOnConnect, isDomainValid, isPluginNameValid, pluginName]);

  useEffect(() => {
    const inventoryName =
      title === WORDPRESS_CMS ? "wp-inventory" : title === OPENCART_CMS ? "oc-inventory" : "xml-inventory";
    setInventoryName(inventoryName);
  }, [title]);


  return (
    <div className={styles.container}>
      {isLoading || isLoadingRefreshData ? (
        <div className={styles.loaderContainer}>
          <Loader width={40} height={40} />
        </div>
      ) : (
        integrationContent
      )}
      <Modal
        isShowModal={isModalOpen}
        setIsShowModal={setIsModalOpen}
        title={`Connect ${title}`}
        actions={modalActions}
      >
        {modalContent}
      </Modal>
    </div>
  );
};
