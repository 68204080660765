import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getData } from "../../service/supabase.js";
import supabase from "../../supabase";

import { NoConversation } from "../../components/NoConversation";
import { Title } from "../../components/Title";
import { Channel } from "../../components/Channel";
import { Button } from "../../components/Button";
import { Modal } from "../../components/Modal";
import {
  Instagram,
  Messenger,
  Telegram,
  Viber,
  WpWidget,
  OLXAccount,
  OcWidget,
  SwWidget,
} from "./components";
import { Loader } from "../../components/Loader";
import { Input } from "../../components/Input";
import channel from "../../assets/img/channel.png";

import styles from "./Channels.module.scss";
import "./fixStyle.scss";

import { ROUTES } from "../../constants/routes";
import { CHANNELS } from "../../constants/channels";
import {
  getDefaultStatus,
  getStatusColor,
  getOlxAccountStatus,
} from "./helpers";

export const ChannelsPage = () => {
  const windowWidth = window.innerWidth;

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const {
    channelTab,
    error,
    errorDescription,
    longTermToken,
    pageId,
    pageDescription,
    instagramId,
    assistantId,
  } = location.state || {};
  const step = useSelector((state) => state.onboarding.step);
  const user = useSelector((state) => state.auth.user);

  const [checked, setChecked] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [isShowModal, setIsShowModal] = useState(false);
  const [channels, setChannels] = useState(CHANNELS);
  const [channelsData, setChannelsData] = useState([]);
  const [selectedChannelData, setSelectedChannelData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [listOfAssistants, setListOfAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState({});

  const handleClick = () => {
    navigate(ROUTES.dashboardPage);
  };

  const fetchData = async () => {
    try {
      // TODO: set assistant based on the tab open

      const { data, status } = await supabase
        .from("channels")
        .select("*")
        .eq("account_id", user.id)
        .eq("assistant_id", selectedAssistant.value);

      if (data?.length !== 0 && status === 200) {
        setChannelsData(data);
        const updatedChannels = CHANNELS.map((channel) => {
          const matchedChannel = data.find(
            ({ communication_channel }) =>
              channel.value === communication_channel,
          );

          const olxAccounts = data.filter(
            ({ communication_channel, is_on }) =>
              communication_channel === "OLX Account" && is_on,
          );

          const statusName = matchedChannel
            ? channel.value === "OLX Account"
              ? getOlxAccountStatus(olxAccounts)
              : getDefaultStatus(matchedChannel, channel)
            : "Connect";

          const statusColor = getStatusColor(
            matchedChannel,
            olxAccounts,
            channel,
          );

          return {
            ...channel,
            status: {
              color: statusColor,
              name: statusName,
            },
          };
        });

        setChannels(updatedChannels);
      } else {
        setChannels(CHANNELS);
        setChannelsData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSubscription = async () => {
    try {
      setIsLoading(true);
      const { data } = await supabase
        .from("subscriptions")
        .select("*")
        .eq("email", user.email);

      setChecked(!!data.length);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInstructions = async () => {
    const { data: instruction } = await getData(
      "assistants",
      "*",
      "account_id",
      user.id,
    );

    if (!instruction.length) return;
    setListOfAssistants(instruction.sort((a, b) => a.id - b.id));
    const { bot_name, id } = instruction[0];
    setSelectedAssistant({ label: bot_name, value: id });
  };

  const updateListOfAssistants = useMemo(() => {
    return listOfAssistants
      .map(({ id, bot_name }) => ({
        value: id,
        label: bot_name,
      }))
      .toSorted((a, b) => a.value - b.value);
  }, [listOfAssistants]);

  useEffect(() => {
    fetchInstructions();
  }, []);

  useEffect(() => {
    if (!selectedAssistant.value && !assistantId) return;
    setActiveTab("");
    fetchData();
  }, [selectedAssistant, assistantId]);

  useEffect(() => {
    const tab = searchParams.get("tab");

    if (tab) {
      setActiveTab(tab);
    }

    if (channelTab) {
      setActiveTab(channelTab);
    }

    if (error) {
      setIsShowModal(true);
    }
  }, []);

  useEffect(() => {
    fetchSubscription();
  }, []);

  useEffect(() => {
    setSelectedChannelData(
      channelsData.filter(
        (channel) => channel.communication_channel === activeTab,
      ),
    );
  }, [activeTab, channelsData]);

  const pageTitle = useMemo(() => {
    if (!selectedAssistant?.label) return "Channels";
    return `Channels for ${selectedAssistant.label}`;
  }, [selectedAssistant]);

  const renderPages = () => {
    const Component = {
      Telegram,
      Viber,
      Instagram,
      Messenger,
      WpWidget,
      OcWidget,
      SwWidget,
      "OLX Account": OLXAccount,
    }[activeTab];

    if (Component)
      return (
        <Component
          key={selectedChannelData}
          channelData={selectedChannelData}
          fetchChannels={fetchData}
          instagramId={instagramId}
          longTermToken={longTermToken}
          pageId={pageId}
          assistantId={selectedAssistant.value || assistantId}
          pageDescription={pageDescription}
        />
      );
    return (
      <div className={styles.emptyChannel}>
        <Title title={"Select channels to connect"} />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.top} top`}>
        <Title title={pageTitle} />
        <Input
          typeInput={"select"}
          placeholder={"Select an assistant"}
          options={updateListOfAssistants}
          value={selectedAssistant}
          onChange={(selectedOption) => setSelectedAssistant(selectedOption)}
        />
      </div>
      {!checked ? (
        isLoading ? (
          <div
            className={styles.loaderContainer}
            style={{
              height:
                windowWidth >= 666
                  ? step === 6
                    ? "100vh"
                    : "calc(100vh - 18rem)"
                  : "",
              maxHeight:
                windowWidth >= 666
                  ? step === 6
                    ? ""
                    : "calc(100vh - 18rem)"
                  : "",
            }}
          >
            <Loader width={60} height={60} />
          </div>
        ) : (
          <div className={styles.wrapper}>
            <div className={styles.integrations}>
              <div className={`${styles.title} ${styles.active}`}>
                <Title title={"Channels"} />
              </div>
              <div className={styles.modalContainer}>
                <div className={styles.modal}>
                  <div className={styles.textModal}>
                    Start free trial to connect your bot to channels
                  </div>
                  <Button
                    onClick={handleClick}
                    className={styles.buttonModal}
                    title={"Start free trial"}
                  />
                </div>
              </div>
              <div className={`${styles.channel} ${styles.active}`}>
                {channels.map((i, index) => (
                  <Channel
                    onClick={() => {
                      setActiveTab(i.value);
                    }}
                    activeTab={activeTab}
                    key={index}
                    title={i.name}
                    icon={i.icon}
                    status={i.status}
                  />
                ))}
              </div>
            </div>
            <div className={styles.emptyContainer}>
              <img src={channel} alt="" />
              <div className={styles.textModal}>
                Select to channel to connect
              </div>
            </div>

            <Modal
              isShowModal={isShowModal}
              title={error}
              description={errorDescription}
              setIsShowModal={setIsShowModal}
            />
          </div>
        )
      ) : step >= 4 ? (
        isLoading ? (
          <div
            className={styles.loaderContainer}
            style={{
              height:
                windowWidth >= 666
                  ? step === 6
                    ? "100vh"
                    : "calc(100vh - 18rem)"
                  : "",
              maxHeight:
                windowWidth >= 666
                  ? step === 6
                    ? ""
                    : "calc(100vh - 18rem)"
                  : "",
            }}
          >
            <Loader width={60} height={60} />
          </div>
        ) : (
          <div className={styles.wrapper}>
            <div className={styles.integrations}>
              <div className={`${styles.channel}`}>
                {channels.map((i, index) => (
                  <Channel
                    onClick={() => {
                      setActiveTab(i.value);
                    }}
                    activeTab={activeTab}
                    key={index}
                    title={i.name}
                    icon={i.icon}
                    value={i.value}
                    status={i.status}
                  />
                ))}
              </div>
            </div>
            <div className={styles.connectionTab}>{renderPages()}</div>

            <Modal
              isShowModal={isShowModal}
              title={error}
              description={errorDescription}
              setIsShowModal={setIsShowModal}
            />
          </div>
        )
      ) : (
        <NoConversation
          title={"No data"}
          description={
            "You’ll see your dashboard as soon as your bot starts messaging with customers"
          }
        />
      )}
    </div>
  );
};
