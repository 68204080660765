import AuthModal from "./AuthModal";
import useAuthConnectionData from "./useAuthConnectionData";
import { Button, Typography } from "../../../../../../components";

import styles from "./AuthConnection.module.scss";

const AuthConnection = () => {
  const {
    selectedIntegrationType,
    isModalOpen,
    setIsModalOpen,
    handleUserConsent,
    showFilePicker,
    handleOpenPicker,
    isAssistantConnected,
    sheetIdUrl,
  } = useAuthConnectionData();

  return (
    <div>
      {sheetIdUrl ? (
        <Typography variant="caption2" className={styles.editSheet}>
          You can edit your spreadsheet{" "}
          <a href={sheetIdUrl} target="_blank" rel="noreferrer">
            here
          </a>
        </Typography>
      ) : null}
      <AuthModal
        isOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleUserConsent={handleUserConsent}
      />
      <Typography variant="caption2">
        Connect your account to {selectedIntegrationType?.name}
      </Typography>
      <Button
        variant="contained"
        title={isAssistantConnected ? "Reconnect" : "Connect"}
        onClick={() => setIsModalOpen(true)}
        className={styles.button}
      />
      {showFilePicker ? (
        <>
          <Typography variant="caption2">
            You can finish connection by selecting file
          </Typography>
          <Button
            variant="contained"
            title="Open file picker"
            onClick={handleOpenPicker}
            className={styles.button}
          />
        </>
      ) : null}
    </div>
  );
};

export default AuthConnection;
