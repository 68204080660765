import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import {
  AIConfigurationPage,
  AuthPage,
  ChannelsPage,
  ChatsPage,
  CheckInstagramPage,
  CheckMessengerPage,
  CheckOLXPage,
  DashboardPage,
  IntegrationsPage,
  ProfilePage,
  RepeatPasswordPage,
  SubscriptionPage,
  UserChatPage,
  KnowledgeBasePage,
  FollowUpPage,
  EditFollowUp,
} from "./pages";
import { PrivateRoutes } from "./routes/PrivateRoutes";

import { ROUTES } from "./constants/routes";
import MessageList from "./pages/ChatsPage/DesktopChat-V2/components/MessageList";
import NotificationsPage from "./pages/NotificationsPage";
import ReferralProgramPage from "./pages/ReferralProgramPage";

function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route
            path={"*"}
            element={<Navigate to={ROUTES.dashboardPage} replace />}
          />

          <Route element={<PrivateRoutes />}>
            <Route element={<DashboardPage />} path={ROUTES.dashboardPage} />
            <Route element={<ChatsPage />} path={ROUTES.chatsPage}>
              <Route element={<MessageList />} path={ROUTES.messagesPage} />
            </Route>
            <Route
              element={<AIConfigurationPage />}
              path={ROUTES.aiConfigurationPage}
            />
            <Route element={<ChannelsPage />} path={ROUTES.channelsPage} />
            <Route
              element={<IntegrationsPage />}
              path={ROUTES.integrationPage}
            />
            <Route
              element={<KnowledgeBasePage />}
              path={ROUTES.knowledgeBasePage}
            />
            <Route element={<FollowUpPage />} path={ROUTES.followUpPage} />
            <Route element={<EditFollowUp />} path={"/follow-up/:id"} />
            <Route
              element={<NotificationsPage />}
              path={ROUTES.notifications}
            />
            <Route
              element={<ReferralProgramPage />}
              path={ROUTES.referralProgramPage}
            />
            {/*<Route element={<SubscriptionPage/>} path={ROUTES.subscriptionPage}/>*/}
            <Route element={<UserChatPage />} path={ROUTES.userChatPage} />
            <Route element={<ProfilePage />} path={ROUTES.profilePage} />
            <Route
              element={<CheckMessengerPage />}
              path={ROUTES.checkMessengerPage}
            />
            <Route
              element={<CheckInstagramPage />}
              path={ROUTES.checkInstagramPage}
            />
            <Route element={<CheckOLXPage />} path={ROUTES.checkOLXPage} />
          </Route>

          <Route
            element={<RepeatPasswordPage />}
            path={ROUTES.repeatPasswordPage}
          />
          <Route element={<AuthPage />} path={ROUTES.loginPage} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
