import React from "react";

import styles from "./Chip.module.scss";
import classNames from "classnames";

const Chip = ({ children, status }) => {
  return (
    <div
      className={classNames(styles.chip, {
        [styles.success]: status === "success",
      })}
    >
      {children}
    </div>
  );
};

export default Chip;
