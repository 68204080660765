import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Burger,
  Channels,
  Chats,
  Configurations,
  Dashboard,
  FollowUp,
  Helpdesk,
  Integrations,
  KnowledgeBase,
  ReferralProgram,
  Subscription,
  Support,
} from "../../../assets/icons";
import logo from "../../../assets/img/logo.png";
import userAvatar from "../../../assets/img/user.png";

import styles from "./MobileNavigator.module.scss";

import { ROUTES } from "../../../constants/routes";
import Notifications from "../Navigation/Notifications/Notifications";

export const MobileNavigator = () => {
  const location = useLocation();

  const user = useSelector((state) => state.auth.user);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleBurgerMenuClick = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <header className={styles.mobileHeader}>
      <div className={styles.burgerMenu} onClick={handleBurgerMenuClick}>
        <Burger />
      </div>
      <div className={styles.logoContainer}>
        <img src={logo} alt="" loading={"lazy"} />
      </div>
      {isSidebarOpen && (
        <>
          <div
            className={styles.sidebarBackdrop}
            onClick={() => {
              setIsSidebarOpen(false);
            }}
          />
          <div className={styles.sidebar}>
            <div className={styles.header}>
              <img src={logo} alt="" loading={"lazy"} />
            </div>
            <div className={styles.container}>
              <div className={styles.navigationContainer}>
                <div>
                  <Link
                    to={ROUTES.dashboardPage}
                    className={`${styles.navigation} ${
                      location.pathname === ROUTES.dashboardPage
                        ? styles.navigationActive
                        : ""
                    }`}
                    onClick={() => {
                      setIsSidebarOpen(false);
                    }}
                  >
                    <Dashboard />
                    DASHBOARD
                  </Link>
                  <Link
                    to={ROUTES.aiConfigurationPage}
                    className={`${styles.navigation} ${
                      location.pathname === ROUTES.aiConfigurationPage
                        ? styles.navigationActive
                        : ""
                    }`}
                    onClick={() => {
                      setIsSidebarOpen(false);
                    }}
                  >
                    <Configurations />
                    AI Configuration
                  </Link>
                  <Link
                    to={ROUTES.chatsPage}
                    className={`${styles.navigation} ${
                      location.pathname.includes("/chats")
                        ? styles.navigationActive
                        : ""
                    }`}
                    onClick={() => {
                      setIsSidebarOpen(false);
                    }}
                  >
                    <Chats />
                    Active Chats
                  </Link>
                  <Link
                    to={ROUTES.channelsPage}
                    className={`${styles.navigation} ${
                      location.pathname === ROUTES.channelsPage
                        ? styles.navigationActive
                        : ""
                    }`}
                    onClick={() => {
                      setIsSidebarOpen(false);
                    }}
                  >
                    <Channels />
                    Channels
                  </Link>
                  <Link
                    to={ROUTES.integrationPage}
                    className={`${styles.navigation} ${
                      location.pathname === ROUTES.integrationPage
                        ? styles.navigationActive
                        : ""
                    }`}
                    onClick={() => {
                      setIsSidebarOpen(false);
                    }}
                  >
                    <Integrations />
                    Integrations
                  </Link>
                  <Link
                    to={ROUTES.knowledgeBasePage}
                    className={`${styles.navigation} ${
                      location.pathname === ROUTES.knowledgeBasePage
                        ? styles.navigationActive
                        : ""
                    }`}
                    onClick={() => {
                      setIsSidebarOpen(false);
                    }}
                  >
                    <KnowledgeBase />
                    Knowledge Base
                  </Link>
                  <Link
                    to={ROUTES.followUpPage}
                    className={`${styles.navigation} ${
                      location.pathname === ROUTES.followUpPage
                        ? styles.navigationActive
                        : ""
                    }`}
                    onClick={() => {
                      setIsSidebarOpen(false);
                    }}
                  >
                    <FollowUp />
                    Follow Ups
                  </Link>
                  <Notifications
                    isMobile={true}
                    setIsSidebarOpen={setIsSidebarOpen}
                  />
                  {/*<Link to={ROUTES.subscriptionPage}
                                        tooltip={"Not applicable for your account"} 
                                          className={`${styles.navigation} ${location.pathname === ROUTES.subscriptionPage ? styles.navigationActive : ''}`}
                                          onClick={() => {
                                              setIsSidebarOpen(false);
                                          }}>
                                        <Subscription/>
                                        Subscription
                                    </Link>*/}
                </div>
                <div className={styles.support}>
                  <Link
                      to={ROUTES.referralProgramPage}
                      className={`${styles.navigation} ${
                          location.pathname === ROUTES.referralProgramPage
                              ? styles.navigationActive
                              : ""
                      }`}
                      onClick={() => {
                        setIsSidebarOpen(false);
                      }}
                  >
                    <ReferralProgram />
                    Referral Program
                  </Link>
                  <div className={styles.navigation}>
                    <Support />
                    <a>Support</a>
                  </div>
                  <div className={styles.navigation}>
                    <Helpdesk />
                    <a>Helpdesk</a>
                  </div>
                </div>
              </div>
              <div className={styles.userCard}>
                {user.app_metadata.provider === "email" ? (
                  <Link
                    to={ROUTES.profilePage}
                    onClick={() => {
                      setIsSidebarOpen(false);
                    }}
                    className={`${styles.profile} ${
                      location.pathname === ROUTES.profilePage
                        ? styles.navigationActive
                        : ""
                    }`}
                  >
                    <img src={userAvatar} alt="avatar" />
                    <div className={styles.userCard__userInfo}>
                      <div className={styles.textInfo}>
                        <div className={styles.userCard__email}>
                          {user?.email}
                        </div>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <Link
                    to={ROUTES.profilePage}
                    onClick={() => {
                      setIsSidebarOpen(false);
                    }}
                    className={`${styles.profile} ${styles.socials} ${
                      location.pathname === ROUTES.profilePage
                        ? styles.navigationActive
                        : ""
                    }`}
                  >
                    <img src={user?.user_metadata?.avatar_url} alt="avatar" />

                    <div className={styles.userCard__userInfo}>
                      <div className={styles.textInfo}>
                        <div className={styles.userCard__name}>
                          {user?.user_metadata?.full_name}
                        </div>
                        <div className={styles.userCard__email}>
                          {user?.user_metadata?.email}
                        </div>
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </header>
  );
};
