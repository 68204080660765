import React from "react";
import styles from "./FollowUpsListItems.module.scss";
import { Button } from "../../../../components/Button";
import { Pen, Trash } from "../../../../assets/icons";
import { useNavigate } from "react-router-dom";
import { ALL_STATUSES } from "../EditFollowUp/const";

const FollowUpsListItems = ({
  followUp,
  onDeleteFollowUpClick,
  onPublishFollowUpClick,
}) => {
  const navigate = useNavigate();

  const onEditFollowUpClick = (followUp) => {
    navigate(`/follow-up/${followUp.id}`);
  };

  return (
    <li key={followUp.id} className={styles.followUpItem}>
      <div className={styles.followUpDetails}>
        <p className={styles.followUpName}>{followUp.name}</p>
        <p>{followUp.status}</p>
        <p>{followUp.type}</p>
        <p>{followUp.executed}</p>
        <p>{followUp.target_status_name || ALL_STATUSES}</p>
      </div>
      <div className={styles.followUpActions}>
        <Button
          disabled={false}
          className={styles.actionsButtons}
          icon={<Pen />}
          onClick={() => {
            onEditFollowUpClick(followUp);
          }}
        />
        <Button
          disabled={false}
          className={styles.actionsButtons}
          icon={<Trash />}
          onClick={() => {
            onDeleteFollowUpClick(followUp);
          }}
        />
        <Button
          disabled={false}
          title={followUp.status === "draft" ? "Publish" : "Unpublish"}
          onClick={() => {
            onPublishFollowUpClick(followUp);
          }}
          className={styles.publishButton}
        />
      </div>
    </li>
  );
};

export default FollowUpsListItems;
