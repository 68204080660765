import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { ControlledPopup } from "../../Popup";
import { Button } from "../../Button";
import { Input } from "../../Input";

import { getAFormOfPayment } from "../../../service/mychatbot.js";

import styles from "./PopupPayment.module.scss";

export const PopupPayment = ({
  isOpenPopup,
  setIsOpenPopup,
  userId,
  tokenPice,
}) => {
  const [htmlString, setHtmlString] = useState("");
  const [quantityTokens, setQuantityTokens] = useState({ value: 1, label: 1 });
  const [price, setPrice] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const wFormRef = useRef(null);

  const options = Array(20)
    .fill(1)
    .map((el, index) => ({ value: 1 + index, label: 1 + index }));

  const closePopup = () => {
    setIsOpenPopup(false);
  };

  const onProceedToPaymentClick = async () => {
    await addAForm();
    setTimeout(() => {
      wFormRef.current.querySelector(".wfpform").submit();
    }, 0);
  };

  const onInput = (value) => {
    setQuantityTokens(value);
  };

  const addAForm = async () => {
    try {
      setDisabled(true);
      const formMarkup = await getAFormOfPayment({
        account: userId,
        amount: quantityTokens.value,
      });
      setHtmlString(formMarkup);
      return formMarkup;
    } catch (error) {
      toast.error(error);
    } finally {
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (!isOpenPopup) return setHtmlString("");
  }, [isOpenPopup, userId]);

  useEffect(() => {
    if (!tokenPice) return;
    setPrice(quantityTokens.value * tokenPice);
  }, [tokenPice, quantityTokens]);

  return (
    <>
      <div
        className={styles.wForm}
        dangerouslySetInnerHTML={{ __html: htmlString }}
        ref={wFormRef}
      ></div>
      <ControlledPopup
        isOpenPopup={isOpenPopup}
        closePopup={closePopup}
        actions={
          <div className={styles.form}>
            <div className={styles.wInput}>
              <label className={styles.iLabel}>Millions of AI Tokens</label>
              <Input
                typeInput={"select"}
                placeholder={"Tokens quantity"}
                value={quantityTokens}
                onChange={onInput}
                options={options}
              />
            </div>
            <div className={styles.formPrice}>
              Total cost for {quantityTokens.value}M AI Tokens would be ${price}
            </div>
            <Button
              className={styles.proceedToPaymentButton}
              onClick={onProceedToPaymentClick}
              title={"Proceed to payment"}
              disabled={disabled}
            />
          </div>
        }
      />
    </>
  );
};
