import React, { useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import supabase from "../supabase";
import { loading, login } from "../store/slices/authSlice";
import { setOnboarding } from "../store/slices/onboardingSlice";

import { QuickSetUp } from "./components/QuickSetUp";
import { MobileNavigator } from "./components/MobileNavigator";
import { Navigation } from "./components/Navigation";

import styles from "./PrivateRoutes.module.scss";
import { Loader } from "../components/Loader";

import { ROUTES } from "../constants/routes";

export const PrivateRoutes = () => {
  //   const windowWidth = window.innerWidth;

  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.auth.isLogin);
  const step = useSelector((state) => state.onboarding.step);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        dispatch(loading(true));
        const { data } = await supabase.auth.getUser();

        if (data) {
          const { data: user } = await supabase
            .from("users")
            .select("*")
            .eq("id", data.user.id);

          if (user.length === 0) {
            await supabase.from("accounts").insert([{ id: data.user.id }]);
            await supabase
              .from("onboardings")
              .insert([{ account_id: data.user.id, step: 1 }]);
            if (data.user.app_metadata.provider === "email") {
              await supabase.from("users").insert([
                {
                  id: data.user.id,
                  account_id: data.user.id,
                  avatar_url: "",
                },
              ]);
            } else {
              await supabase.from("users").insert([
                {
                  id: data.user.id,
                  account_id: data.user.id,
                  avatar_url: data.user.user_metadata.avatar_url,
                },
              ]);
            }
          } else {
            const { data: onboarding_user } = await supabase
              .from("onboardings")
              .select("*")
              .eq("account_id", data.user.id);
            dispatch(setOnboarding(onboarding_user[0].step));
          }
          dispatch(login({ isLogin: true, user: data.user }));
        }
      } catch (error) {
        dispatch(login({ isLogin: false, user: {} }));
        dispatch(loading(false));
      } finally {
        dispatch(loading(false));
      }
    };

    fetchUser();
  }, []);

  const isLoading = useSelector((state) => state.auth.isLoading);

  if (isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader height={80} width={80} />
      </div>
    );
  }

  return isLogin ? (
    <div className={styles.container}>
      <MobileNavigator />
      <Navigation />
      {/* {(windowWidth <= 666) &&
                    <MobileNavigator/>
                }
                {(windowWidth >= 666) &&
                    <Navigation/>
                } */}
      <div className={styles.wrapper}>
        {step !== 6 && <QuickSetUp />}
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to={ROUTES.loginPage} />
  );
};
