import { useCallback, useMemo, useState } from "react";
import DurationPicker from "react-duration-picker";

import { Modal } from "../../../../../../components/Modal";
import CustomFollowUpDropdown from "../../../CustomFollowUpDropdown/CustomFollowUpDropdown";
import { Button } from "../../../../../../components/Button";
import { Typography } from "../../../../../../components/Typography/Typography";
import { EDGE_CONDITION_TYPES } from "../../const";
import { useFollowUpPageContext } from "../../../../context/useFollowUpPageContext";

import styles from "./EditEdgeModal.module.scss";

const selectOptions = [
  EDGE_CONDITION_TYPES.reply,
  EDGE_CONDITION_TYPES.silence,
];

export function EditEdgeModal() {
  const {
    isEditEdgeModalOpen,
    setIsEditEdgeModalOpen,
    updateEdgeData,
    edges,
    selectedEdgeId,
  } = useFollowUpPageContext();
  const edge = useMemo(
    () => edges.find((edge) => edge.id === selectedEdgeId),
    [edges, selectedEdgeId]
  );
  const [edgeOption, setEdgeOption] = useState(edge?.data?.condition || null);
  const [edgeData, setEdgeData] = useState(edge?.data?.value || "");

  const handleOnSave = useCallback(() => {
    updateEdgeData({
      edgeOption,
      edgeData,
    });
    setEdgeOption(null);
    setIsEditEdgeModalOpen(false);
  }, [updateEdgeData, edgeOption, edgeData, setIsEditEdgeModalOpen]);

  const replyOptionContent = (
    <div className={styles.replyOptionContent}>
      <Typography variant="body2">Example:</Typography>
      <Typography variant="body2" className={styles.replyOptionDescription}>
        "user responded"/ "user is married" / "user said yes"
      </Typography>
      <textarea
        placeholder="New Pathway"
        className={styles.replyOptionTextarea}
        onChange={(e) => {
          setEdgeData(e.target.value);
        }}
        defaultValue={
          edge?.data?.condition === EDGE_CONDITION_TYPES.reply
            ? edge?.data?.value
            : ""
        }
      />

      <div className={styles.replyOptionExplanation}>
        <Typography variant="h4">Explanation</Typography>

        <Typography variant="body2">
          Under what circumstances should the agent follow this pathway? Provide
          additional details on when it's appropriate. Feel free to include user
          input examples that would trigger this pathway.
        </Typography>

        <Typography variant="body2">Example:</Typography>
        <Typography variant="body2">
          "This pathway is Choosen exclusively if the user explicitly requests
          to speak with an agent or expresses frustration with the bot's
          responses. For instance, phrases like 'I want to speak to a human' or
          'I want to talk to an agent' should prompt this pathway."
        </Typography>
      </div>
    </div>
  );

  const silenceOptionContent = (
    <div className={styles.silenceOptionContent}>
      <DurationPicker
        maxHours={23}
        onChange={(newValue) => {
          setEdgeData(newValue);
        }}
        initialDuration={edge?.data?.value || ""}
      />
    </div>
  );

  return (
    <Modal
      isShowModal={isEditEdgeModalOpen}
      title="Select condition"
      modalClassName={styles.modal}
      setIsShowModal={setIsEditEdgeModalOpen}
      actions={
        <div className={styles.actionsWrapper}>
          <Typography variant="body2" className={styles.description}>
            When connecting nodes, the agent relies on pathway labels for
            decision-making. Keep labels brief and clear.
          </Typography>
          <div className={styles.selectWrapper}>
            <CustomFollowUpDropdown
              options={selectOptions}
              placeholder={edge?.data?.condition || "Select condition"}
              onChange={setEdgeOption}
            />
          </div>
          {edgeOption === EDGE_CONDITION_TYPES.reply
            ? replyOptionContent
            : edgeOption === EDGE_CONDITION_TYPES.silence
            ? silenceOptionContent
            : null}
          <div className={styles.controls}>
            <Button
              title="Save"
              variant="contained"
              color="primary"
              onClick={handleOnSave}
            />
          </div>
        </div>
      }
    />
  );
}
