import React, { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import supabase from "../../supabase";
import {
  useIntegrationTokenMutation,
  useValidateIntegrationAssetsMutation,
} from "../../store/api";

import { Button } from "../Button";
import { Modal } from "../Modal";
import { Loader } from "../Loader";
import { Input } from "../Input";

import { Plus } from "../../pages/KnowledgeBasePage/assets/icons";
import { validateIntegrationTimeOut } from "../../constants/message";
import styles from "./Integration.module.scss";

export const IntegrationGoogleAuth = (props) => {
  const {
    id,
    title,
    icon,
    scope,
    description,
    connected,
    fetchIntegrations,
    authType,
    isLoadingRefreshData,
    isWorking,
    sheetId,
    children,
    knowledgeBase,
    docLink = "",
  } = props;
  const user = useSelector((state) => state.auth.user);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isShowIntegrationForm, setIsShowIntegrationForm] = useState(false);
  const [isConsentModalOpen, setIsConsentModalOpen] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [accessTokenCrm, setAccessTokenCrm] = useState("");

  const handleConnectClick = (authType) => {
    if (authType === "apikey") {
      setIsShowIntegrationForm(true);
      return;
    }
    setIsConsentModalOpen(true);
  };

  const handleUserConsent = (consent) => {
    setIsConsentModalOpen(false);
    if (consent) {
      connected ? reconnectToken(title, id) : login();
    }
  };

  const [integrationToken] = useIntegrationTokenMutation();
  const [validateIntegrationAssets] = useValidateIntegrationAssetsMutation();

  const login = useGoogleLogin({
    scope: scope,
    flow: "auth-code",
    onSuccess: async (codeResponse) => {
      await getIntegrationToken(codeResponse.code);
    },
  });

  const updateIntegrationAssets = (updateObject) => {
    try {
      return supabase
        .from("integrations")
        .update(updateObject)
        .match({ account_id: user.id, access_token: accessToken });
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const pickerCallback = async (data) => {
    try {
      if (data.action === "picked") {
        const docId = data.docs[0].id;
        const updateObject = { sheet_id: docId };
        await validateIntegration(docId);
        await updateIntegrationAssets(updateObject);
        setAccessToken("");
        fetchIntegrations();
      }
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const handleOpenPicker = (integrationTitle) => {
    let view;
    switch (integrationTitle) {
      case "Spreadsheet Inventory":
      case "Spreadsheet Orders":
        view = window.google.picker.ViewId.SPREADSHEETS;
        break;
      default:
        view = window.google.picker.ViewId.DOCUMENTS;
    }

    const showPicker = () => {
      // TODO(developer): Replace with your API key
      const picker = new window.google.picker.PickerBuilder()
        .setAppId("14528528059-6fds545h4jtt5lr1tm88bp6i2bh73g1k")
        .addView(view)
        .setOAuthToken(accessToken)
        .setDeveloperKey(process.env.REACT_APP_GOOGLE_PICKER_API_KEY)
        .setCallback(pickerCallback)
        .build();
      picker.setVisible(true);
    };

    showPicker();
  };

  const reconnectToken = async (integrationTitle, id) => {
    try {
      setIsLoading(true);
      const { error: errorSupabase } = await supabase
        .from("integrations")
        .delete()
        .eq("account_id", user.id)
        .eq("id", id)
        .eq("integration", integrationTitle);

      if (errorSupabase) {
        toast.error(errorSupabase);
        return;
      }

      if (authType !== "apikey") {
        setAccessToken("");
      } else {
        setAccessTokenCrm("");
      }
    } catch (error) {
      setIsError(true);
      setErrorText(error.message);
    } finally {
      fetchIntegrations();
      setIsLoading(false);
    }
  };

  const validateIntegration = async (sheetId) => {
    try {
      setIsLoading(true);
      const resp = await validateIntegrationAssets({
        account_id: user.id,
        integration: title,
        access_token: accessToken,
        sheet_id: sheetId,
      });

      if (resp.error) {
        toast.error(resp.error.data ? resp.error.data.error : resp.error);
        setIsError(true);
        setErrorText("" + resp.error);
      } else {
        toast.success(resp.data);
      }
    } catch (error) {
      setIsError(true);
      setErrorText(validateIntegrationTimeOut);
    } finally {
      fetchIntegrations();
      setIsLoading(false);
    }
  };

  const getIntegrationToken = async (auth_code) => {
    try {
      setIsLoading(true);
      const resp = await integrationToken({
        account_id: user.id,
        integration: title,
        auth_code: auth_code,
        knowledge_base: knowledgeBase,
      });

      if (resp.error) {
        toast.error(resp.error.data ? resp.error.data.error : resp.error);
      } else {
        setAccessToken(resp.data.access_token);
      }
    } catch (error) {
      setIsError(true);
      setErrorText("" + error);
    } finally {
      setIsLoading(false);
    }
  };

  const setCrmIntegration = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      const integrationRes = await integrationToken({
        account_id: user.id,
        integration: title,
        access_token: accessTokenCrm,
      });

      if (integrationRes.error) {
        toast.error(integrationRes.error);
        return;
      }

      const validateIntegrationRes = await validateIntegrationAssets({
        account_id: user.id,
        integration: title,
        access_token: accessTokenCrm,
      });

      if (validateIntegrationRes.error) {
        setAccessTokenCrm("");
        setIsShowIntegrationForm(false);
        await reconnectToken(title, id);
        toast.error(validateIntegrationRes.error);
      }
    } catch (error) {
      setIsError(true);
      setErrorText("" + error);
    } finally {
      fetchIntegrations();
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      {isLoading || isLoadingRefreshData ? (
        <div className={styles.loaderContainer}>
          <Loader width={40} height={40} />
        </div>
      ) : (
        <>
          {docLink !== "" && (
            <a target="_blank" href={docLink} className={styles.link}>
              link
            </a>
          )}
          <div className={styles.iconContainer}>
            <div className={styles.iconBox}>{icon ? icon : children}</div>
            {!!title && <div className={styles.title}>{title}</div>}
            {!!description && (
              <div className={styles.description}>{description}</div>
            )}
          </div>
          {connected ? (
            <Button
              onClick={() => reconnectToken(title, id)}
              style={{ background: "#59d259" }}
              className={styles.button}
              title={"REVOKE"}
            />
          ) : accessToken && isWorking ? (
            <Button
              onClick={() => handleOpenPicker(title)}
              className={styles.button}
              style={{ background: "#d3d407" }}
              title={"PICK FILE"}
            />
          ) : isShowIntegrationForm ? (
            <form className={styles.form} onSubmit={setCrmIntegration}>
              <Input
                typeInput="connected"
                placeholder="Your Access Token"
                value={accessTokenCrm}
                onChange={(e) => setAccessTokenCrm(e.target.value)}
                // error={!!errorText.length}
              />
              <Button
                type="submit"
                className={styles.button}
                style={{ background: "#d3d407" }}
                title={"SUBMIT"}
              />
            </form>
          ) : (
            <Button
              onClick={() => handleConnectClick(authType)}
              className={styles.button}
              title={
                title === "Choose your unit" ? (
                  <Plus height={"1.25rem"} />
                ) : (
                  "CONNECT"
                )
              }
              disabled={!isWorking}
            />
          )}
          <Modal
            isShowModal={isError}
            title={"Error"}
            description={errorText}
            setIsShowModal={setIsError}
          />
        </>
      )}
      <Modal
        isShowModal={isConsentModalOpen}
        title={"User Consent Required"}
        /*description={'Do you allow third-party AI models to read the data you provided in the required for the integration Google Drive documents?'}*/
        descriptionSegments={[
          "In order to integrate with our services, we require access to certain documents in your Google Drive. We use advanced third-party AI models to process this data exclusively for reading purposes and to enhance conversations with your clients.",
          "Before proceeding, we need your explicit consent for the following:",
          "1. Allow us to access specific Google Drive documents that you designate as necessary for the integration.",
          "2. Permit the use of this data by third-party AI models exclusively for reading and facilitating conversations with your clients, and for no other purpose.",
          "We assure you that:",
          "- Only the data in the documents you specify will be accessed.",
          "- Your data will be used solely for the purposes of enhancing client interactions and not for any other reason.",
          "- You can withdraw your consent and restrict access at any time.",
          "Do you agree to these terms and grant us permission to access and use your data as described above?",
        ]}
        actions={
          <div style={{ display: "flex", flexDirectin: "row", width: "100%" }}>
            <Button
              className={styles.button}
              style={{ margin: "10px 10px 10px 0" }}
              onClick={() => handleUserConsent(true)}
              title={"Yes"}
            />
            <Button
              className={styles.button}
              style={{ margin: "10px 10px 10px 0" }}
              onClick={() => handleUserConsent(false)}
              title={"No"}
            />
          </div>
        }
        setIsShowModal={setIsConsentModalOpen}
      />
    </div>
  );
};
