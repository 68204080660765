import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAvailableIntegrations,
  selectSelectedAssistant,
  selectSelectedIntegrationType,
  setSelectedIntegrationType,
} from "../../../../store/slices/integrationSlice";
import { Chip, Typography } from "../../../../components";
import {
  INTEGRATION_NAMES,
  INTEGRATIONS,
} from "../../../../constants/integrations";

import styles from "./AvailableIntegrations.module.scss";
import classNames from "classnames";
import { TOOL_TYPE } from "../../../../constants/general";

const AvailableIntegrations = () => {
  const availableIntegrations = useSelector(selectAvailableIntegrations);
  const selectedIntegrationType = useSelector(selectSelectedIntegrationType);
  const selectedAssistant = useSelector(selectSelectedAssistant);
  const dispatch = useDispatch();

  const getAvailableIntegrationsByName = useCallback(
    (name) => {
      return availableIntegrations
        ?.filter((integration) => integration.integration === name)
        .map((integration) => integration.id);
    },
    [availableIntegrations],
  );

  const updatedIntegrations = useMemo(() => {
    if (!availableIntegrations) return INTEGRATIONS;

    return INTEGRATIONS.map((i) => {
      const matchedChannel = availableIntegrations?.find(
        ({ integration }) => i.name === integration,
      );

      const availableIntegrationsCurrentName = getAvailableIntegrationsByName(
        i.name,
      );

      const isAssistantConnected = selectedAssistant?.integrations?.some(
        (integrationId) =>
          availableIntegrationsCurrentName?.includes(integrationId),
      );

      return matchedChannel
        ? {
            ...i,
            connected: isAssistantConnected ?? false,
            id: matchedChannel.id,
          }
        : i;
    });
  }, [
    availableIntegrations,
    getAvailableIntegrationsByName,
    selectedAssistant,
  ]);

  const onIntegrationClick = (integration) => {
    const { authType, name, knowledge_base, scope } = integration;

    dispatch(
      setSelectedIntegrationType({ authType, name, knowledge_base, scope }),
    );
  };

  const connectedCRMIntegration = useMemo(() => {
    return updatedIntegrations?.find(
      (integration) =>
        integration.type === TOOL_TYPE.CRM && integration.connected,
    );
  }, [updatedIntegrations]);

  const checkIfIntegrationIsDisabled = useCallback(
    (integration) => {
      return (
        integration.type === TOOL_TYPE.CRM &&
        connectedCRMIntegration &&
        integration.id !== connectedCRMIntegration.id
      );
    },
    [connectedCRMIntegration],
  );

  return (
    <div className={styles.container}>
      {updatedIntegrations?.map((integration) => {
        const isDisabled = checkIfIntegrationIsDisabled(integration);

        return (
          <div
            key={integration.name}
            className={classNames(styles.integrationItem, {
              [styles.selected]:
                integration.name === selectedIntegrationType?.name,
              [styles.disabled]: isDisabled,
            })}
            onClick={() => {
              if (isDisabled) return;
              onIntegrationClick(integration);
            }}
          >
            <div className={styles.integrationInfo}>
              <div className={styles.iconContainer}>{integration.icon}</div>
              <Typography variant="body1" className={styles.title}>
                {integration.name}
              </Typography>
            </div>
            <Chip status={integration.connected ? "success" : null}>
              {integration.connected ? "Connected" : "Connect"}
            </Chip>
          </div>
        );
      })}
    </div>
  );
};

export default AvailableIntegrations;
