import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import Switch from "react-switch";
import { Loader, Typography } from "../../../../components";
import {
  selectAvailableIntegrations,
  selectSelectedAssistant,
  selectSelectedIntegrationsIdsByType,
  selectSelectedIntegrationType,
} from "../../../../store/slices/integrationSlice";

import styles from "./IntegrationSettings.module.scss";
import { INTEGRATION_TYPES } from "../../../../constants/integrations";
import {
  AuthConnection,
  KeyConnection,
  ConnectToExistingIntegration,
} from "./components";
import useUpdateAssistantIntegrations from "../../useUpdateAssistantIntegrations";
import { useLoadingContext } from "../../useLoadingContext";

const IntegrationSettings = () => {
  const selectedAssistant = useSelector(selectSelectedAssistant);
  const selectedIntegrationType = useSelector(selectSelectedIntegrationType);
  const availableIntegrations = useSelector(selectAvailableIntegrations);
  const selectedIntegrationsIdsByType = useSelector(
    selectSelectedIntegrationsIdsByType
  );
  const { isLoading, setIsLoading } = useLoadingContext();
  const { removeAssistantIntegration } = useUpdateAssistantIntegrations();
  const currentTypeAssistantIntegrationId = useMemo(() => {
    return selectedAssistant?.integrations?.find((id) =>
      selectedIntegrationsIdsByType.includes(id)
    );
  }, [selectedAssistant, selectedIntegrationsIdsByType]);

  const isAssistantConnected = useMemo(() => {
    if (
      !selectedAssistant ||
      !selectedIntegrationType ||
      !selectedAssistant?.integrations
    ) {
      return false;
    }

    return selectedAssistant?.integrations?.some((integrationId) =>
      selectedIntegrationsIdsByType.includes(integrationId)
    );
  }, [selectedAssistant, selectedIntegrationType, availableIntegrations]);

  const placeholderText = useMemo(() => {
    if (!selectedAssistant) {
      return "Select assistant to connect";
    }
    if (!selectedIntegrationType) {
      return "Select integration to connect";
    }
    return "";
  }, [selectedAssistant, selectedIntegrationType]);

  const connectionContentByType = useMemo(() => {
    switch (selectedIntegrationType?.authType) {
      case INTEGRATION_TYPES.OAUTH:
        return <AuthConnection />;
      case INTEGRATION_TYPES.API_KEY:
      case INTEGRATION_TYPES.API_REFRESH_TOKEN:
        return <KeyConnection />;
      default:
        return null;
    }
  }, [selectedIntegrationType]);

  const onRemoveAssistantIntegration = useCallback(async () => {
    if (isAssistantConnected) {
      setIsLoading(true);
      await removeAssistantIntegration(currentTypeAssistantIntegrationId);
      setIsLoading(false);
    }
  }, [isAssistantConnected, currentTypeAssistantIntegrationId]);

  if (isLoading) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {selectedIntegrationType && selectedAssistant ? (
        <div className={styles.contentWrapper}>
          <div className={styles.switchWrapper}>
            <Switch
              onChange={onRemoveAssistantIntegration}
              checked={isAssistantConnected}
              uncheckedIcon={false}
              checkedIcon={false}
              height={27}
              offColor={"#E3E6EE"}
              onColor={"#3588E9"}
              disabled={!isAssistantConnected}
            />
            <Typography variant="caption2">
              Connect assistant to this channel
            </Typography>
          </div>
          <div className={styles.settingsConnection}>
            <div className={styles.reconnect}>
              <Typography variant="caption2" className={styles.subtitle}>
                "{selectedIntegrationType.name}" integration
              </Typography>
              <ConnectToExistingIntegration />
            </div>
            {connectionContentByType}
          </div>
        </div>
      ) : (
        <div className={styles.placeholder}>
          <Typography variant="title">{placeholderText}</Typography>
        </div>
      )}
    </div>
  );
};

export default IntegrationSettings;
