export const formatDate = (isoDateString) => {
  let date = new Date(isoDateString);

  let hours = date.getHours();
  let minutes = String(date.getMinutes()).padStart(2, "0");

  let amOrPm = hours < 12 ? "AM" : "PM";

  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }

  hours = String(hours).padStart(2, "0");

  return `${hours}:${minutes} ${amOrPm}`;
};
