import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { openInNewTab } from "../../../utils";

import {
  Channels,
  Chats,
  Configurations,
  Dashboard,
  Helpdesk,
  Integrations,
  Subscription,
  Support,
  KnowledgeBase,
  FollowUp,
  ReferralProgram,
} from "../../../assets/icons";
import logo from "../../../assets/img/logo.png";
import userAvatar from "../../../assets/img/user.png";

import styles from "./Navigation.module.scss";

import { ROUTES } from "../../../constants/routes";
import Notifications from "./Notifications/Notifications";

export const Navigation = () => {
  const location = useLocation();

  const user = useSelector((state) => state.auth.user);

  return (
    <div className={styles.container}>
      <div className={styles.navigationContainer}>
        <div>
          <img src={logo} alt="logo" />
          <Link
            to={ROUTES.dashboardPage}
            className={`${styles.navigation} ${
              location.pathname === ROUTES.dashboardPage
                ? styles.navigationActive
                : ""
            }`}
          >
            <Dashboard />
            DASHBOARD
          </Link>
          <Link
            to={ROUTES.aiConfigurationPage}
            className={`${styles.navigation} ${
              location.pathname === ROUTES.aiConfigurationPage
                ? styles.navigationActive
                : ""
            }`}
          >
            <Configurations />
            AI Configuration
          </Link>
          <Link
            to={ROUTES.chatsPage}
            className={`${styles.navigation} ${
              location.pathname.includes("chats") ? styles.navigationActive : ""
            }`}
          >
            <Chats />
            Active Chats
          </Link>
          <Link
            to={ROUTES.channelsPage}
            className={`${styles.navigation} ${
              location.pathname === ROUTES.channelsPage
                ? styles.navigationActive
                : ""
            }`}
          >
            <Channels />
            Channels
          </Link>
          <Link
            to={ROUTES.integrationPage}
            className={`${styles.navigation} ${
              location.pathname === ROUTES.integrationPage
                ? styles.navigationActive
                : ""
            }`}
          >
            <Integrations />
            Integrations
          </Link>
          {/* <Link
            to={ROUTES.subscriptionPage}
            className={`${styles.navigation} ${
              location.pathname === ROUTES.subscriptionPage
                ? styles.navigationActive
                : ""
            }`}
          >
            <Subscription />
            Subscription
          </Link> */}
          <Link
            to={ROUTES.knowledgeBasePage}
            className={`${styles.navigation} ${
              location.pathname === ROUTES.knowledgeBasePage
                ? styles.navigationActive
                : ""
            }`}
          >
            <KnowledgeBase />
            Knowledge base
          </Link>
          <Link
            to={ROUTES.followUpPage}
            className={`${styles.navigation} ${
              location.pathname === ROUTES.followUpPage
                ? styles.navigationActive
                : ""
            }`}
          >
            <FollowUp />
            Follow Ups
          </Link>
          <Notifications />
        </div>
        <div>
          <Link
            to={ROUTES.referralProgramPage}
            className={`${styles.navigation} ${
              location.pathname === ROUTES.referralProgramPage
                ? styles.navigationActive
                : ""
            }`}
          >
            <ReferralProgram />
            Referral Program
          </Link>
          <div
            className={styles.navigation}
            onClick={() => {
              openInNewTab(`https://mychatbot.app/#form`);
            }}
          >
            <Support />
            <a>Support</a>
          </div>
          {/* <div
            className={styles.navigation}
            onClick={() => {
              openInNewTab(
                "https://mychatbotapp.notion.site/MyChatBot-Dashboard-Help-Desk-ee17365fcb164548b82dcfde64c38c24"
              );
            }}
          >
            <Helpdesk />
            <a>Helpdesk</a>
          </div> */}
        </div>
      </div>
      <div className={styles.userCard}>
        {user.app_metadata.provider === "email" ? (
          <Link
            to={ROUTES.profilePage}
            className={`${styles.profile} ${
              location.pathname === ROUTES.profilePage
                ? styles.navigationActive
                : ""
            }`}
          >
            <img src={userAvatar} alt="avatar" />
            <div className={styles.userCard__userInfo}>
              <div className={styles.textInfo}>
                <div className={styles.userCard__email}>{user?.email}</div>
              </div>
            </div>
          </Link>
        ) : (
          <Link
            to={ROUTES.profilePage}
            className={`${styles.profile} ${styles.socials} ${
              location.pathname === ROUTES.profilePage
                ? styles.navigationActive
                : ""
            }`}
          >
            <img src={user?.user_metadata?.avatar_url} alt="avatar" />

            <div className={styles.userCard__userInfo}>
              <div className={styles.textInfo}>
                <div className={styles.userCard__name}>
                  {user?.user_metadata?.full_name}
                </div>
                <div className={styles.userCard__email}>
                  {user?.user_metadata?.email}
                </div>
              </div>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};
