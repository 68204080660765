import styles from "./IntegrationsPage.module.scss";
import { useDispatch } from "react-redux";
import { fetchInstructions as fetchAssistants } from "../../store/slices/assistantSlice";
import { useEffect } from "react";
import {
  IntegrationHeader,
  AvailableIntegrations,
  IntegrationSettings,
} from "./components";
import { fetchIntegrations } from "../../store/slices/integrationSlice";
import { LoadingProvider } from "./useLoadingContext";

export const IntegrationsPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAssistants());
    dispatch(fetchIntegrations());
  }, [dispatch]);

  return (
    <LoadingProvider>
      <div className={styles.container}>
        <IntegrationHeader />

        <div className={styles.contentWrapper}>
          <AvailableIntegrations />
          <IntegrationSettings />
        </div>
      </div>
    </LoadingProvider>
  );
};
