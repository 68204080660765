import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import supabase from "../../supabase";

const initialState = {
  selectedAssistantId: null,
  selectedIntegrationType: null,
  availableIntegrations: [],
};

export const fetchIntegrations = createAsyncThunk(
  "integration/fetchIntegrations",
  async (_, { getState }) => {
    const userId = getState().auth.user.id;
    const { data } = await supabase
      .from("integrations")
      .select("*")
      .eq("account_id", userId)
      .eq("is_knowledge_base", false);

    return data;
  }
);

export const integrationSlice = createSlice({
  name: "integration",
  initialState,
  reducers: {
    setSelectedAssistantId: (state, action) => {
      state.selectedAssistantId = action.payload;
    },
    setSelectedIntegrationType: (state, action) => {
      state.selectedIntegrationType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchIntegrations.fulfilled, (state, action) => {
      state.availableIntegrations = action.payload;
    });
  },
});

export const { setSelectedAssistantId } = integrationSlice.actions;
export const { setSelectedIntegrationType } = integrationSlice.actions;

export const selectSelectedAssistant = (state) => {
  const selectedAssistantId = state.integration.selectedAssistantId;
  return state.assistant.listOfAssistants.find(
    (assistant) => assistant.id === selectedAssistantId
  );
};
export const selectAvailableIntegrations = (state) =>
  state.integration.availableIntegrations;
export const selectSelectedIntegrationType = (state) =>
  state.integration.selectedIntegrationType;
export const selectSelectedIntegrationsIdsByType = (state) => {
  if (
    !state.integration.selectedIntegrationType ||
    !state.integration.availableIntegrations
  ) {
    return [];
  }

  return state.integration.availableIntegrations
    ?.filter(
      (integration) =>
        integration.integration ===
        state.integration.selectedIntegrationType.name
    )
    .map((integration) => integration.id);
};

export default integrationSlice.reducer;
