import { Typography } from "../../components";
import { ReferralActionsBlock } from "./components";

import styles from "./ReferralProgramPage.module.scss";
import StatisticsBlock from "./components/StatisticsBlock/StatisticsBlock";

const ReferralProgramPage = () => {
  return (
    <div className={styles.container}>
      <Typography variant="title">Referral program</Typography>
      <div className={styles.divider} />
      <div className={styles.contentWrapper}>
        <ReferralActionsBlock />
        <StatisticsBlock />
      </div>
      <Typography variant="body1" className={styles.footerText}>
        Tell your friends about our service on social networks, or send them an
        invitation by mail, give them 1 000 000 MyChatBot tokens and get 10%
        from each invitee for 6 months on all their successful payments from
        their card for MyChatBot services.
      </Typography>
    </div>
  );
};

export default ReferralProgramPage;
