import React from "react";
import { Input } from "../../../../../components";
import { INTEGRATION_NAMES } from "../../../../../constants/integrations";

const RenderIntegrationService = ({
  selectedIntegrationName,
  fields,
  handleInputChange,
  isAuthTypeApiRefreshToken,
}) => {
  switch (selectedIntegrationName) {
    case INTEGRATION_NAMES.KEYCRM:
      return (
        <>
          <Input
            placeholder="API Key"
            value={fields.apiKey || ""}
            onChange={handleInputChange("apiKey")}
          />
          <Input
            placeholder="CRM Pipeline Id"
            type="number"
            value={fields.crmPipelineId || ""}
            onChange={handleInputChange("crmPipelineId")}
          />
        </>
      );

    case INTEGRATION_NAMES.ALTERGIO:
      return (
        <>
          <Input
            placeholder="Partner Token"
            value={fields.partnerToken || ""}
            onChange={handleInputChange("partnerToken")}
          />
          <Input
            placeholder="User Access Token"
            value={fields.userAccessToken || ""}
            onChange={handleInputChange("userAccessToken")}
          />
          <Input
            placeholder="Company ID"
            type="number"
            value={fields.companyId || ""}
            onChange={handleInputChange("companyId")}
          />
        </>
      );

    case INTEGRATION_NAMES.BITRIX:
      return (
        <>
          <Input
            placeholder="Access Token"
            value={fields.accessTokenBitrix || ""}
            onChange={handleInputChange("accessTokenBitrix")}
          />
          <Input
            placeholder="Refresh Token"
            value={fields.refreshTokenBitrix || ""}
            onChange={handleInputChange("refreshTokenBitrix")}
          />
          <Input
            placeholder="Domain"
            value={fields.domain || ""}
            onChange={handleInputChange("domain")}
          />
          <Input
            placeholder="Client ID"
            value={fields.clientId || ""}
            onChange={handleInputChange("clientId")}
          />
          <Input
            placeholder="Client Secret"
            value={fields.clientSecret || ""}
            onChange={handleInputChange("clientSecret")}
          />
        </>
      );

    // add case for another intagrations

    default:
      return (
        <>
          <Input
            placeholder={
              isAuthTypeApiRefreshToken ? "Partner Token" : "API key"
            }
            value={
              isAuthTypeApiRefreshToken
                ? fields.partnerToken || ""
                : fields.apiKey || ""
            }
            onChange={handleInputChange(
              isAuthTypeApiRefreshToken ? "partnerToken" : "apiKey"
            )}
          />
          {isAuthTypeApiRefreshToken && (
            <Input
              placeholder="Refresh Token"
              value={fields.refreshToken || ""}
              onChange={handleInputChange("refreshToken")}
            />
          )}
        </>
      );
  }
};

export default RenderIntegrationService;
