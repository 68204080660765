import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";

import { addDays } from "date-fns";
import supabase from "../../supabase";
import { toast } from "react-toastify";
import { IoIosWarning } from "react-icons/io";
import { getData } from "../../service/supabase.js";

import { NoConversation } from "../../components/NoConversation";
import { DatePicker } from "../../components/DatePicker";
import { Loader } from "../../components/Loader";
import { DetailedInfo } from "../../components/DetailedInfo";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { setOnboarding } from "../../store/slices/onboardingSlice";
import { Modal } from "../../components/Modal";
import { PopupPayment } from "../../components/popups/PopupPayment";

import ChartsVertical2 from "./components/ChartsVertical2";

import { errorMesPayment, tokensPerMessage } from "../../constants/message.js";

import styles from "./Dashboard.module.scss";
import "./fixStyle.scss";
import classNames from "classnames";

export const DashboardPage = () => {
  const windowWidth = window.innerWidth;

  const user = useSelector((state) => state.auth.user);
  const step = useSelector((state) => state.onboarding.step);

  const [botName, setBotName] = useState("");

  const [tokensLeft, setTokensLeft] = useState(0);
  const [approximateMessagesLeft, setApproxMessagesLeft] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorDescription, setErrorDescription] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [chatTokens, setChatTokens] = useState(null);
  const [filteredChatTokens, setFilteredChatTokens] = useState(null);

  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [listOfAssistants, setListOfAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState([]);
  const [tokenPice, setTokenPice] = useState(0);

  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [range, setRange] = useState([
    {
      startDate: addDays(new Date(), -31),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!open && chatTokens?.length) {
      const filteredData = filteredChatsData(chatTokens);
      setFilteredChatTokens(filteredData);
    }
  }, [open]);

  useEffect(() => {
    if (!chatTokens?.length) return;
    const filteredData = filteredChatsData(chatTokens);
    setFilteredChatTokens(filteredData);
  }, [selectedAssistant, chatTokens]);

  const filteredChatsData = (chatsData = []) => {
    const assistant = selectedAssistant.map(({ value }) => value);
    const items = chatsData?.filter(({ created_at }) => {
      const createdAt = new Date(created_at).getTime();
      const { startDate, endDate } = range[0];
      return createdAt >= startDate.getTime() && createdAt <= endDate.getTime();
    });
    if (!assistant?.length) return items;
    return items.filter(({ assistant_id }) => assistant.includes(assistant_id));
  };

  const onTopUpClick = () => {
    setIsOpenPopup(true);
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const { data } = await supabase
        .from("assistants")
        .select("*")
        .eq("account_id", user.id);
      setBotName(data[0]?.bot_name || "");

      const { data: accountData } = await supabase
        .from("accounts")
        .select("*")
        .eq("id", user.id);

      const { data: subscriptionData } = await supabase
        .from("subscriptions")
        .select("*")
        .eq("email", user.email);

      if (accountData[0]?.token_price) {
        setTokenPice(accountData[0]?.token_price);
      }

      if (subscriptionData.length !== 0) {
        await supabase
          .from("onboardings")
          .update({ step: 6 })
          .match({ account_id: user.id });
        dispatch(setOnboarding(6));

        setTokensLeft(subscriptionData[0]?.tokens_left || 0);
        setApproxMessagesLeft(
          Math.floor(subscriptionData[0].tokens_left / tokensPerMessage) || 0
        );
      } else {
        await supabase.from("subscriptions").insert([
          {
            account_id: user.id,
            email: user.email,
            order_id: "blablabla",
            tokens_left: 0,
          },
        ]);
      }

      const { data: chatsData } = await supabase
        .from("chat_token_usage")
        .select("*")
        .eq("account_id", user?.id)
        .gte("created_at", "1970-01-01T00:00:00Z")
        .order("created_at", "asc")
        .limit(100000);
      if (chatsData && chatsData?.length > 0) {
        setChatTokens(chatsData);
        const filteredData = filteredChatsData(chatsData);
        setFilteredChatTokens(filteredData);
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInstructions = async () => {
    const { data: instruction } = await getData(
      "assistants",
      "*",
      "account_id",
      user.id
    );

    if (!instruction.length) return;
    setListOfAssistants(instruction);
  };

  const updateListOfAssistants = useMemo(() => {
    return listOfAssistants
      .map(({ id, bot_name, status }) => ({
        value: id,
        label: bot_name,
        status,
      }))
      .toSorted((a, b) => a.value - b.value);
  }, [listOfAssistants]);

  useEffect(() => {
    if (!updateListOfAssistants.length) return;
    setSelectedAssistant(updateListOfAssistants);
  }, [updateListOfAssistants]);

  const botTitle = useMemo(() => {
    const length = selectedAssistant?.length;
    if (length === 1) {
      return `Hello, your assistant ${selectedAssistant[0]?.label} has: `;
    }
    if (length === listOfAssistants?.length) {
      return `Hello, your all assistants have: `;
    }
    if (length > 1) {
      return `Hello, your assistants ${selectedAssistant
        .map(({ label }) => label.trim())
        .join(", ")} have: `;
    }

    return "No assistant selected";
  }, [selectedAssistant]);

  useEffect(() => {
    fetchInstructions();
    fetchData();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.get("paymentfailed") === "true") {
      setErrorDescription(errorMesPayment);
      setIsModalVisible(true);
    }
  }, [location.search]);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div
          className={styles.loaderContainer}
          style={{
            height:
              windowWidth >= 666
                ? step === 6
                  ? "100vh"
                  : "calc(100vh - 18rem)"
                : "",
            maxHeight:
              windowWidth >= 666
                ? step === 6
                  ? ""
                  : "calc(100vh - 18rem)"
                : "",
          }}
        >
          <Loader width={40} height={40} />
        </div>
      ) : botName !== "" ? (
        <>
          <div className={`${styles.switchContainer} my-row`}>
            <Input
              isMulti={true}
              typeInput={"select"}
              placeholder={"Select an assistant"}
              options={updateListOfAssistants}
              value={selectedAssistant}
              onChange={(selectedOption) =>
                setSelectedAssistant(selectedOption)
              }
            />
          </div>
          <div className={`${styles.chartWrapper} customScroll dashboard`}>
            <div className={styles.chartContainer}>
              <div className={styles.textInfo}>
                <div className={styles.chatDatepicker}>
                  <div className={styles.chartText__welcome}>
                    {botTitle && <p>{botTitle}</p>}
                  </div>
                  <div className={styles.calendarWrap}>
                    <DatePicker
                      range={range}
                      setRange={setRange}
                      open={open}
                      setOpen={setOpen}
                    />
                  </div>
                </div>
                <div className={styles.chartInfoContainer}>
                  <div className={styles.chartInfo}>
                    <div className={styles.chartInfo__number}>{tokensLeft}</div>
                    <div className={styles.chartText}>
                      AI tokens, or approx.{" "}
                    </div>
                  </div>
                  <div className={styles.chartInfo}>
                    <div className={styles.chartInfo__number}>
                      {approximateMessagesLeft}
                    </div>
                    <div className={styles.chartText}>AI messages left</div>
                  </div>

                  {tokensLeft <= 30000 && (
                    <div className={styles.chartInfo}>
                      <IoIosWarning className={styles.IoIosWarning} />
                      <div
                        className={styles.chartText}
                        style={{ color: "rgb(239, 30, 36)" }}
                      >
                        Your token balance is low, your assistant will be turned
                        off soon.
                      </div>
                    </div>
                  )}

                </div>

                <div className={styles.buttons}>
                  <Button
                    className={styles.topUpButton}
                    onClick={onTopUpClick}
                    title={"Top Up Tokens"}
                  />
                </div>
                <div className={styles.infoBox}>
                  <DetailedInfo
                    chatTokens={filteredChatTokens}
                    userId={user.id}
                  />
                  <div>
                    {filteredChatTokens && (
                      <ChartsVertical2 chatTokens={filteredChatTokens} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (<>
            <NoConversation
                title={"No data"}
                description={
                  "First you need to create your AI Assistant in the AI Configuration tab"
                }
            />
            <div className={classNames(styles.buttons, styles.topUpButtonWrapper)}>
              <Button
                  className={styles.topUpButton}
                  onClick={onTopUpClick}
                  title={"Top Up Tokens"}
              />
            </div>
          </>
      )}
      <Modal
          title={"Error"}
          description={errorDescription}
          setIsShowModal={setIsModalVisible}
          isShowModal={isModalVisible}
      />
      <PopupPayment
          tokenPice={tokenPice}
          userId={user.id}
          isOpenPopup={isOpenPopup}
          setIsOpenPopup={setIsOpenPopup}
      />
    </div>
  );
};
