import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {useLongTermTokenMutation} from '../../store/api';

import {Loader} from '../../components/Loader';

import styles from './CheckMessenger.module.scss';

import {ROUTES} from '../../constants/routes';

export const CheckMessengerPage = () => {

    const navigate = useNavigate();

    const user = useSelector(state => state.auth.user);

    const [isLoading, setIsLoading] = useState(false);

    const [longTermToken] = useLongTermTokenMutation();

    const handleError = (errorDescription = 'No pages found for the user', error = 'Error') => {
        setIsLoading(false);

        navigate(ROUTES.channelsPage, {
            state: {
                channelTab: 'Messenger',
                error,
                errorDescription,
            }
        });
    }

    const accessTokenRef = useRef(null);
    const longLivedTokenRef = useRef(null);

    const tokenRequest = async () => {
        try {
            setIsLoading(true);

            const urlParams = new URLSearchParams(window.location.search);
            const hashUrlParams = new URLSearchParams(window.location.hash?.substring(1));
            const accessToken = hashUrlParams.get('access_token');
            const longLivedToken = hashUrlParams.get('long_lived_token')
            const state = hashUrlParams.get('state');

            if (accessToken && user.id === state) {
                accessTokenRef.current = accessToken;
                longLivedTokenRef.current = longLivedToken;
                console.log("here", accessToken)
                const {error, data} = await longTermToken({
                    access_token: accessToken,
                    long_lived_token: longLivedToken,
                    is_instagram: false,
                });

                if (error) {
                    handleError(error);
                } else if (data) {
                    if (data.error) {
                        handleError(data.error)
                    } else {
                        navigate(ROUTES.channelsPage, {
                            state: {
                                channelTab: 'Messenger',
                                longTermToken: data.page_long_lived_token,
                                pageId: data.page_id,
                                pageDescription: data.page_description,
                            }
                        });
                    }
                } else {
                    navigate(ROUTES.channelsPage, {state: {channelTab: 'Messenger'}});
                }
            }


            const error = urlParams.get('error');
            const error_description = urlParams.get('error_description');

            if (error) {
                handleError(error_description);
            }

        } catch (error) {
            setIsLoading(false);
            handleError();
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        tokenRequest();
    }, []);

    return (
        isLoading && <div className={styles.loaderContainer}>
            <Loader width={80} height={80}/>
        </div>
    );
};
