import { useEffect } from "react";
import { Typography } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotifications,
  selectAllNotifications,
} from "../../store/slices/notificationsSlice";
import NotificationsTableHeader from "./components/NotificationsTableHeader/NotificationsTableHeader";
import NotificationsTableBody from "./components/NotificationsTableBody/NotificationsTableBody";

import styles from "./NotificationsPage.module.scss";

const NotificationsPage = () => {
  const notifications = useSelector(selectAllNotifications);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchNotifications());
  }, [dispatch]);

  return (
    <div>
      <Typography variant="title" className={styles.title}>
        Notifications
      </Typography>
      <div className={styles.container}>
        {notifications?.length ? (
          <div className={styles.tableWrapper}>
            <NotificationsTableHeader />
            <NotificationsTableBody />
          </div>
        ) : (
          <Typography variant="body1">No notifications</Typography>
        )}
      </div>
    </div>
  );
};

export default NotificationsPage;
