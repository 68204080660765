import React, { useEffect, useRef } from "react";
import Select from "react-select";

import styles from "./Input.module.scss";
import classNames from "classnames";

export const Input = ({
  value,
  onChange,
  placeholder,
  type,
  disabled,
  error,
  errorText,
  typeInput = "default",
  inputTitle,
  options,
  maxlength,
  isMulti = false,
  customClass: customClassName,
}) => {
  const textareaRef = useRef();

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "0.06rem solid #727272",
      borderRadius: "0.5rem",
      fontFamily: "Montserrat",
      fontSize: "1.25rem",
      fontWeight: "500",
      lineHeight: "1.5rem",
      letterSpacing: 0,
      color: "#727272",
      backgroundColor: "#E3E6EE",
    }),
    option: (provided, { isDisabled, isFocused, isSelected }) => {
      return {
        ...provided,
        backgroundColor: isDisabled
          ? null
          : isSelected
            ? "#E5E7FB"
            : isFocused
              ? "#E5E7FB"
              : null,
        color: isDisabled ? "#727272" : isSelected ? "#727272" : "#727272",
        fontFamily: "Montserrat",
        fontSize: "1.25rem",
        fontWeight: "500",
        lineHeight: "1.25rem",
      };
    },
    input: (provided) => ({
      ...provided,
      height: "1rem",
      minHeight: "1rem",
      display: "flex",
      alignItems: "center",
    }),
    singleValue: (provided) => ({
      ...provided,
      marginTop: 0,
    }),
    menu: (provided) => ({
      ...provided,
      margin: 0,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "inherit";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + "px";
    }
  }, [value]);

  return (
    <>
      {(() => {
        switch (typeInput) {
          case "default":
            return (
              <>
                {!!inputTitle && (
                  <div
                    className={classNames(styles.title, styles.defaultTitle)}
                  >
                    {inputTitle}
                  </div>
                )}
                <input
                  type={type}
                  className={classNames(
                    `${styles.input} ${(error || errorText) ? styles.error : ""}  ${
                      type === "password" && value !== "" ? styles.password : ""
                    }`,
                    {
                      [customClassName]: customClassName,
                      [inputTitle]: styles.inputWithTitle,
                    },
                  )}
                  value={value}
                  onChange={onChange}
                  placeholder={placeholder}
                  disabled={disabled}
                />
                {errorText && (
                  <div className={styles.errorText}>{errorText}</div>
                )}
              </>
            );
          case "select":
            return (
              <>
                {!!inputTitle && (
                  <div className={styles.title}>{inputTitle}</div>
                )}
                <Select
                  menuPortalTarget={document.body}
                  className={`${styles.select} my-select`}
                  placeholder={placeholder}
                  value={value}
                  isMulti={isMulti}
                  onChange={onChange}
                  options={options}
                  styles={customStyles}
                  menuPlacement="auto"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </>
            );
          case "aiinput":
            return (
              <>
                {!!inputTitle && (
                  <div className={styles.title}>{inputTitle}</div>
                )}
                <input
                  type={type}
                  className={styles.aiInput}
                  value={value}
                  onChange={onChange}
                  placeholder={placeholder}
                />
                {errorText && (
                  <div className={styles.errorText}>{errorText}</div>
                )}
              </>
            );
          case "connected":
            return (
              <>
                {!!inputTitle && (
                  <div className={styles.title}>{inputTitle}</div>
                )}
                <input
                  type={type}
                  className={styles.connected}
                  disabled={disabled}
                  value={value}
                  onChange={onChange}
                  placeholder={placeholder}
                />
                {errorText && (
                  <div className={styles.errorText}>{errorText}</div>
                )}
              </>
            );
          case "textarea":
            return (
              <>
                {!!inputTitle && (
                  <div className={styles.title}>{inputTitle}</div>
                )}
                <div>
                  <textarea
                    maxLength={maxlength}
                    ref={textareaRef}
                    rows={3}
                    className={styles.textarea}
                    style={
                      inputTitle === "Role and instructions"
                        ? { minHeight: "25rem" }
                        : {}
                    }
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                  />
                  {errorText && (
                    <div className={styles.errorText}>{errorText}</div>
                  )}
                </div>
              </>
            );
          default:
            return null;
        }
      })()}
    </>
  );
};
