export function processMessageContent(message) {
  var content = message.content;
  if (!content) {
    content = "";
  }
  if (message.MultiContent) {
    // iterate over the MultiContent array and add each content to the message
    // if image_url is not empty then add it to the content and if text is not empty then add it to the content
    message.MultiContent.forEach((multiContentPart) => {
      /* if (multiContentPart.image_url) {
        content += multiContentPart.image_url.url + "\n";
      } */
      if (multiContentPart.text) {
        content += multiContentPart.text;
      }
    });
  }

  //const urlRegex = /(https?:\/\/[^\s]+)/g;
  const urlRegex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()\*@:%_\+.~#?&//=]*)/g;
  //const urlRegex = /(https?:\/\/[^\s]+)/g;
  let instagramStoryLink = null;
  let images = [];

  // Check for the specific prefix in the content
  //const storyMentionPrefix = content.match(/You were mentioned in a story by ([\w.]+): /);

  // we need to take care of this prefix the same way as the story reply prefix
  //const storyReplyPrefix = content.match(/replied to a story: /);

  // Extract the URL if it's from lookaside.fbsbx.com/ig_messaging_cdn
  const match = content.match(urlRegex);
  var hasStoryLink = false;
  if (match) {
    match.forEach((url) => {
      if (url.includes("api.mychatbot.app/stories/")) {
        hasStoryLink = true;
        instagramStoryLink = url;
        content = content.replace(url, ""); // Remove URL from the content
      } else if (
        url.includes("lookaside.fbsbx.com/ig_messaging_cdn") ||
        url.includes("cdninstagram.com")
      ) {
        // avoid duplicates:
        if (!images.includes(url)) {
          images.push(url);
        }
        content = content.replace(url, ""); // Remove URL from the content
      } else if (url.includes("lh3.googleusercontent.com")) {
        // Google Photos URL
        if (!images.includes(url)) {
          images.push(url);
        }
        content = content.replace(url, ""); // Remove URL from the content
      } else {
        // if last character is a ) then remove it
        url = url.replace(/\)$/, "");

        // if link id google drive like this: https://drive.google.com/uc?export=view&id={image_id}
        // then we want to make it like this: https://drive.google.com/thumbnail?id={image_id}&sz=w1000
        if (url.includes("drive.google.com")) {
          url = url.replace(/\/uc\?export=view/, "/thumbnail?sz=w1000");
        }

        // avoid duplicates:
        if (!images.includes(url)) {
          images.push(url);
        }
      }
    });

    if (hasStoryLink) {
      images = [];
    }
  }

  content = content.replace(/^\n+/, "");
  content = content.replace(/[\s\n\r]+$/, "");

  return { content, images, instagramStoryLink };
}

export function getGMTOffset(dateString) {
  const date = new Date(dateString);
  const timezoneOffset = date.getTimezoneOffset();
  const hours = Math.floor(Math.abs(timezoneOffset) / 60);
  const sign = timezoneOffset <= 0 ? "+" : "-";

  return `GMT ${sign}${hours}`;
}
