import { useDispatch, useSelector } from "react-redux";
import { updateData } from "../../service/supabase";
import {
  selectSelectedAssistant,
  selectSelectedIntegrationsIdsByType,
} from "../../store/slices/integrationSlice";
import {
  fetchInstructions as fetchAssistants,
  selectListOfAssistants,
} from "../../store/slices/assistantSlice";
import { useCallback, useMemo } from "react";
import useUpdateIntegrationData from "./useUpdateIntegrationData";

const useUpdateAssistantIntegrations = () => {
  const listOfAssistants = useSelector(selectListOfAssistants);
  const selectedAssistant = useSelector(selectSelectedAssistant);
  const selectedIntegrationsIdsByType = useSelector(
    selectSelectedIntegrationsIdsByType
  );
  const dispatch = useDispatch();
  const { deleteIntegration } = useUpdateIntegrationData();

  const updateAssistantIntegrations = async (assistantId) => {
    await updateData("assistants", { integrations: [] }, { id: assistantId });
  };

  const currentTypeAssistantIntegrationId = useMemo(() => {
    return selectedAssistant?.integrations?.find((id) =>
      selectedIntegrationsIdsByType.includes(id)
    );
  }, [selectedAssistant, selectedIntegrationsIdsByType]);

  const addAssistantIntegration = useCallback(
    async (integrationId, currentAssistantIntegrationsProps = null) => {
      const currentAssistantIntegrations =
        currentAssistantIntegrationsProps ??
        selectedAssistant?.integrations ??
        [];

      await updateData(
        "assistants",
        { integrations: [...currentAssistantIntegrations, integrationId] },
        { id: selectedAssistant.id }
      );
    },
    [selectedAssistant]
  );

  const checkIntegrationUsedByOtherAssistant = useCallback(
    (integrationId) => {
      return listOfAssistants.some(
        (assistant) =>
          assistant?.integrations?.includes(integrationId) &&
          assistant?.id !== selectedAssistant?.id
      );
    },
    [listOfAssistants, selectedAssistant]
  );

  const removeAssistantIntegration = useCallback(
    async (integrationId, currentAssistantIntegrationsProps = null) => {
      const currentAssistantIntegrations =
        currentAssistantIntegrationsProps ??
        selectedAssistant?.integrations ??
        [];
      const updatedIntegrations = currentAssistantIntegrations?.filter(
        (id) => id !== integrationId
      );

      await updateData(
        "assistants",
        { integrations: updatedIntegrations },
        {
          id: selectedAssistant.id,
        }
      );

      const isIntegrationUsed =
        checkIntegrationUsedByOtherAssistant(integrationId);

      if (!isIntegrationUsed) {
        await deleteIntegration(integrationId);
      }

      await dispatch(fetchAssistants());

      return updatedIntegrations;
    },
    [listOfAssistants, selectedAssistant]
  );

  return {
    currentTypeAssistantIntegrationId,
    addAssistantIntegration,
    removeAssistantIntegration,
    updateAssistantIntegrations,
  };
};

export default useUpdateAssistantIntegrations;
