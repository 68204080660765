import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Typography } from "../../../../../../components";
import {
  selectAvailableIntegrations,
  selectSelectedAssistant,
  selectSelectedIntegrationsIdsByType,
  selectSelectedIntegrationType,
} from "../../../../../../store/slices/integrationSlice";
import { useIntegrationChecks } from "../useIntegrationChecks";
import useKeyConnectionData from "./useKeyConnectionData";
import styles from "../../IntegrationSettings.module.scss";
import { INTEGRATION_NAMES } from "../../../../../../constants/integrations";
import { REQUIRED_FIELDS } from "../../../../../../constants/integrations";
import RenderIntegrationService from "../RenderIntegrationService";

const KeyConnection = () => {
  const {
    createConnection,
    isLoading,
    updateField,
    getFieldsForIntegration,
    resetFieldsForIntegration,
    setIntegrationFields,
  } = useKeyConnectionData();

  const { isAuthTypeApiRefreshToken, isAltegio, isKeyCRM, isBitrix } =
    useIntegrationChecks();

  const selectedIntegrationType = useSelector(selectSelectedIntegrationType);
  const selectedIntegrationName = selectedIntegrationType?.name;

  const selectedAssistant = useSelector(selectSelectedAssistant);
  const availableIntegrations = useSelector(selectAvailableIntegrations);
  const selectedIntegrationsIdsByType = useSelector(
    selectSelectedIntegrationsIdsByType
  );

  const fields = getFieldsForIntegration(selectedIntegrationName);

  const handleInputChange = (fieldName) => (e) => {
    updateField(selectedIntegrationName, fieldName, e.target.value);
  };

  const isAssistantConnected = selectedAssistant?.integrations?.some(
    (integrationId) => selectedIntegrationsIdsByType.includes(integrationId)
  );

  const filteredIntegration = availableIntegrations.find(
    ({ integration }) => integration === selectedIntegrationName
  );

  useEffect(() => {
    if (filteredIntegration) {
      const existingFields = {};

      switch (selectedIntegrationName) {
        case INTEGRATION_NAMES.KEYCRM:
          existingFields.apiKey = filteredIntegration?.access_token || "";
          existingFields.crmPipelineId =
            filteredIntegration?.metadata?.crm_pipeline_id || "";
          break;

        case INTEGRATION_NAMES.ALTERGIO:
          existingFields.partnerToken = filteredIntegration?.access_token || "";
          existingFields.userAccessToken =
            filteredIntegration?.refresh_token || "";
          existingFields.companyId =
            filteredIntegration?.metadata?.company_id || "";

          break;

        case INTEGRATION_NAMES.BITRIX:
          existingFields.accessTokenBitrix =
            filteredIntegration?.access_token || "";
          existingFields.refreshTokenBitrix =
            filteredIntegration?.refresh_token || "";
          existingFields.domain = filteredIntegration?.metadata?.domain || "";
          existingFields.clientId =
            filteredIntegration?.metadata?.client_id || "";
          existingFields.clientSecret =
            filteredIntegration?.metadata?.client_secret || "";

          break;

        // add case for another integration

        default:
          break;
      }

      setIntegrationFields((prev) => ({
        ...prev,
        [selectedIntegrationName]: existingFields,
      }));
    } else {
      // If integration is not found, clear the fields
      resetFieldsForIntegration(selectedIntegrationName);
    }
  }, [
    selectedIntegrationType,
    filteredIntegration,
    selectedIntegrationName,
    setIntegrationFields,
    resetFieldsForIntegration,
  ]);

  const isSubmitDisabled = () => {
    if (isAssistantConnected) return true;

    const integrationName = selectedIntegrationName;

    const requiredFields = REQUIRED_FIELDS[integrationName] || ["apiKey"];

    return requiredFields.some((field) => !fields[field]);
  };

  return (
    <div className={styles.newConnection}>
      <Typography variant="caption2">
        Create new "{selectedIntegrationType.name}" integration by pasting a new{" "}
        {isAuthTypeApiRefreshToken
          ? "Partner Token and User Access Token"
          : "API key"}
      </Typography>

      <div
        className={`${styles.apiInputWrapper} ${
          isAltegio || isBitrix ? styles.columnDirection : ""
        }`}
      >
        <RenderIntegrationService
          selectedIntegrationName={selectedIntegrationName}
          fields={fields}
          handleInputChange={handleInputChange}
          isAuthTypeApiRefreshToken={isAuthTypeApiRefreshToken}
        />
        <Button
          variant="contained"
          title="Submit"
          onClick={createConnection}
          disabled={isSubmitDisabled()}
        />
      </div>
    </div>
  );
};

export default KeyConnection;
