import { Typography } from "../../../../components";
import { useGetReferralStatisticsQuery } from "../../../../store/api";
import { selectUser } from "../../../../store/slices/authSlice";
import { useSelector } from "react-redux";
import StatisticsCard from "../StatisticsCard/StatisticsCard";

import styles from "./StatisticsBlock.module.scss";

const StatisticsBlock = () => {
  const user = useSelector(selectUser);
  const { data: referralStatistics } = useGetReferralStatisticsQuery(user?.id);

  return (
    <div className={styles.container}>
      <Typography variant="h2">Statistics</Typography>
      <div className={styles.cardsWrapper}>
        {referralStatistics
          ? Object.entries(referralStatistics).map(([key, value]) => (
              <StatisticsCard key={key} title={key} value={value} />
            ))
          : null}
      </div>
    </div>
  );
};

export default StatisticsBlock;
