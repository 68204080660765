import Markdown from "react-markdown";
import styles from "./MdToHTML.module.css";
import rehypeRaw from "rehype-raw";

export const MdToHTML = ({ content }) => {
  // replace google drive links here
  content = content.replace(/https:\/\/drive.google.com\/file\/d\/[a-zA-Z0-9_-]+\/view/g, (match) => {
    return convertGoogleDriveLinkToDirectImageLink(match);
  }); 

  return (
    content &&
    typeof content === "string" && (
      <Markdown
        className={styles.reactMarkDown}
        skipHtml={false}
        rehypePlugins={[rehypeRaw]}
      >
        {content}
      </Markdown>
    )
  );
};

function convertGoogleDriveLinkToDirectImageLink(shareableLink, width = 1000) {
  // Case if the link is not a google drive link
  if (!shareableLink.includes("drive.google.com")) {
    return shareableLink;
  }

  const parts = shareableLink.split('/');
  if (parts.length < 6) {
    return "Invalid link format";
  }

  const fileId = parts[5];

  // Construct the direct image link using the lh3.googleusercontent.com domain
  const directLink = `https://lh3.googleusercontent.com/d/${fileId}=w${width}`;
  return directLink;
}

