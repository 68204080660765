import React from "react";

import styles from "./Channel.module.scss";

export const Channel = ({ title, icon, onClick, activeTab, value, status }) => {
  const handleClick = () => {
    onClick();
  };

  return (
    <div
      onClick={handleClick}
      className={styles.container}
      style={{ backgroundColor: activeTab === value ? "#F5F5F5" : "" }}
    >
      <div className={styles.iconContainer}>
        {icon}
        <div className={styles.title}>{title}</div>
      </div>
      <div
        style={{ backgroundColor: status?.color ?? "#3588e9" }}
        className={styles.button}
      >
        {status?.name ?? "Connect"}
      </div>
    </div>
  );
};
