import styles from "./MessageItem.styles.module.scss";
import { processMessageContent } from "../../../../helpers";
import { Manager, Viber } from "../../../../../../../assets/icons";
import { FaBookOpen } from "react-icons/fa";
import { formatDate } from "../../../../../../../utils";
import Knowledge from "../Knowledge";
import classNames from "classnames";
import { MdToHTML } from "../../../../../../../components/MdToHTML";
import { ReplyMessage } from "../ReplyMessage/ReplyMessage";

const MessageItem = ({
  knowledgeHints,
  message,
  isSending,
  handleClickSpecMessageSave,
  handleChangeSpecMessageTextarea,
  handleClickSpecMessageIcon,
}) => {
  const { content, images, instagramStoryLink } =
    processMessageContent(message);

  const manageStr = "Manager: ";
  const viberStr = "Viber: ";
  const isManagerStr = content.includes(manageStr);
  const isViberStr = content.includes(viberStr);

  const newContent = isManagerStr
    ? content.substring(manageStr.length).trim()
    : isViberStr
    ? content.substring(viberStr.length).trim()
    : content;

  // Витягуємо відповідь на інше повідомлення, якщо воно є
  const replyToMessage = message?.MultiContent?.find(
    (item) => item.type === "text" && item.text.includes("Reply to:")
  );

  // Видаляємо "Reply to:" з контенту тексту для відображення
  let cleanedContent = newContent;
  if (replyToMessage) {
    cleanedContent = cleanedContent.replace(/Reply to:.*$/, "").trim();
  }

  return (
    <div className={styles.containerMessage}>
      <div className={`${styles.message} ${styles[message.role]}`}>
        {/* If this is a reply to another message */}
        {replyToMessage && (
          <ReplyMessage
            originalMessage={replyToMessage.text.split("Reply to: ")[1]}
          />
        )}

        {isManagerStr && (
          <div className={styles.sentVia}>
            <Manager />
          </div>
        )}

        {isViberStr && (
          <div className={styles.sentVia}>
            <Viber />
          </div>
        )}

        {cleanedContent && <MdToHTML content={cleanedContent} />}

        {instagramStoryLink && (
          <iframe
            alt=""
            src={instagramStoryLink}
            style={{ maxHeight: "400px", maxWidth: "415px" }}
            frameBorder="0"
            rel="instagram story"
            title="instagram story"
          ></iframe>
        )}

        {images?.length > 0 &&
          message.role === "user" &&
          images?.map((image, index) => (
            <img
              key={index}
              alt=""
              src={image}
              onLoad={(e) => {
                const { naturalWidth, naturalHeight } = e.target;
                if (naturalWidth === 1 && naturalHeight === 1) {
                  e.target.style.display = "none";
                }
              }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src =
                  "https://user-images.githubusercontent.com/20684618/31289519-9ebdbe1a-aae6-11e7-8f82-bf794fdd9d1a.png";
              }}
              style={{
                marginTop: "3px",
                maxHeight: "450px",
                maxWidth: "415px",
                objectFit: "contain",
                background: "e5e5ea",
              }}
              target="_blank"
              rel="image"
            />
          ))}

        <FaBookOpen
          size={24}
          className={styles.specMessageIcon}
          title="Click to improve answer"
          onClick={() => handleClickSpecMessageIcon(message.id)}
        />
      </div>

      <div className={classNames(styles.messageDate, styles[message.role])}>
        {formatDate(message?.created_at)}
      </div>

      <Knowledge
        knowledgeHints={knowledgeHints}
        message={message}
        isSending={isSending}
        handleClickSpecMessageSave={handleClickSpecMessageSave}
        handleChangeSpecMessageTextarea={handleChangeSpecMessageTextarea}
      />
    </div>
  );
};

export default MessageItem;
