import React from "react";
import styles from "./Chats.module.scss";
import DesktopChatV2 from "./DesktopChat-V2";
// import { DesktopChat } from "./DesktopChat";

export const ChatsPage = () => {
  return (
    <div className={styles.container}>
      <DesktopChatV2 />
      {/* <DesktopChat /> */}
    </div>
  );
};
