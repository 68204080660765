import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import supabase from "../../../../supabase";
import { setOnboarding } from "../../../../store/slices/onboardingSlice";
import { useRemoveChannelMutation } from "../../../../store/api";
import { useSetupWebhookMutation } from "../../../../store/api";

import { Title } from "../../../../components/Title";
import { Button } from "../../../../components/Button";
import { Loader } from "../../../../components/Loader";
import { Modal } from "../../../../components/Modal";

import styles from "./Instagram.module.scss";

export const Instagram = ({
  channelData,
  fetchChannels,
  longTermToken,
  pageId,
  pageDescription,
  instagramId,
  assistantId,
}) => {
  const channel = channelData[0];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const step = useSelector((state) => state.onboarding.step);
  const user = useSelector((state) => state.auth.user);

  const [isCheck, setIsCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorDescription, setErrorDescription] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [removeChannel] = useRemoveChannelMutation();
  const [setupWebhook] = useSetupWebhookMutation();

  const handleChange = async () => {
    try {
      setIsLoading(true);
      if (!isCheck) {
        await setupChannelWebhook("Instagram");
      } else {
        const { error } = await supabase
          .from("channels")
          .update({ is_on: false })
          .match({
            account_id: user.id,
            communication_channel: "Instagram",
            assistant_id: assistantId
          });
        if (error) {
          throw new Error("Failed to change channel status");
        }
      }
      setIsCheck(!isCheck);
    } catch (error) {
      setErrorDescription(error.message);
      setIsModalVisible(true);
    } finally {
      await fetchChannels();
      setIsLoading(false);
    }
  };

  const openInstagramAuth = () => {
    window.location.href = `https://www.facebook.com/v17.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_CLIENT_ID}&display=popup&extras={"setup":{"channel":"IG_API_ONBOARDING"}}&redirect_uri=${process.env.REACT_APP_DOMAIN}/oauth/instagram&response_type=token&scope=instagram_manage_messages+instagram_basic+pages_messaging+pages_show_list+pages_manage_metadata&state=${user.id}`;
  };

  const saveToken = async () => {
    setIsLoading(true);
    try {
      const { error } = await supabase.from("channels").upsert([
        {
          account_id: user.id,
          access_token: longTermToken,
          page_id: pageId,
          instagram_id: instagramId,
          page_description: pageDescription,
          communication_channel: "Instagram",
          assistant_id: assistantId,
          is_on: false,
        },
      ]);
      navigate(".", { state: undefined });
      await fetchChannels();
      if (error) {
        throw new Error("Failed to insert new channel");
      } else {
        if (step === 4) {
          const { error } = await supabase
            .from("onboardings")
            .update({ step: 5 })
            .match({ account_id: user.id });
          if (error) {
            throw new Error("Failed to update data");
          }
          dispatch(setOnboarding(5));
        }
      }
    } catch (error) {
      setErrorDescription(error.message);
      setIsModalVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  const setupChannelWebhook = async (channel) => {
    const { error } = await setupWebhook({
      channel: channel,
      account_id: user.id,
    });
    if (error) {
      throw new Error("Failed to setup webhook");
    }
  };

  const revokeToken = async () => {
    try {
      setIsLoading(true);
      const { error: supabaseError } = await supabase
        .from("channels")
        .delete()
        .eq("account_id", user.id)
        .eq("communication_channel", "Instagram");
      if (supabaseError) {
        throw new Error("Failed to remove channel");
      }
      await fetchChannels();
    } catch (error) {
      setErrorDescription(error.message);
      setIsModalVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsCheck(channel?.is_on ?? false);
  }, [channel]);

  useEffect(() => {
    if (longTermToken) {
      saveToken();
    }
  }, [longTermToken, assistantId]);

  return (
    <>
      <Title title={"Instagram"} />

      <div className={styles.enableBot}>
        <Switch
          disabled={!channel?.access_token}
          onChange={handleChange}
          checked={isCheck}
          uncheckedIcon={false}
          checkedIcon={false}
          height={27}
          offColor={"#E3E6EE"}
          onColor={"#3588E9"}
        />
        <div className={styles.enableBotText}>
          Connect assistant to this channel
        </div>
      </div>
      <div className={styles.connectionBot}>
        {isLoading ? (
          <Loader width={40} height={40} />
        ) : (
          <>
            {channel?.access_token && (
              <p className={styles.pageDescription}>
                Page: {channel.page_description}
              </p>
            )}
            {channel?.access_token ? (
              <Button
                onClick={revokeToken}
                className={styles.saveOptimize}
                title={"REVOKE TOKEN"}
              />
            ) : (
              <Button
                onClick={openInstagramAuth}
                className={styles.saveOptimize}
                title={"CONNECT INSTAGRAM"}
              />
            )}
          </>
        )}
      </div>

      <Modal
        title={"Error"}
        description={errorDescription}
        setIsShowModal={setIsModalVisible}
        isShowModal={isModalVisible}
      />
    </>
  );
};
