import React, { useEffect, useMemo, useState } from "react";
import styles from "./FollowUp.module.scss";
import { Button } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  useAddFollowUpMutation,
  useDeleteFollowUpMutation,
  usePublishFollowUpMutation,
} from "../../store/api";
import FollowUpsListItems from "./components/FollowUpsListItems/FollowUpsListItems";
import { ControlledPopup } from "../../components/Popup";
import classNames from "classnames";
import { Typography } from "../../components";
import {
  fetchFollowUps,
  selectFollowUps,
} from "../../store/slices/followUpsSlice";

export const FollowUpPage = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const followUps = useSelector(selectFollowUps);
  const [modalNameValue, setModalNameValue] = useState("");
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isCreateRowShown, setIsCreateRowShown] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupClassName, setPopupClassName] = useState("");
  const [publishFollowTitleButton, setPublishFollowTitleButton] =
    useState("null");
  const deleteFollowUpMessage = "You sure you want to delete this Follow Up?";
  const publishFollowUpMessage = "You sure you want to publish this Follow Up?";
  const unpublishFollowUpMessage =
    "You sure you want to unpublish this Follow Up?";

  const [followUpToDelete, setFollowUpToDelete] = useState(null);
  const [followUpToPublish, setFollowUpToPublish] = useState(null);

  const [
    addFollowUp,
    {
      isLoading: isAdding,
      isError: isErrorAddFollowUp,
      error: errorAddFollowUp,
    },
  ] = useAddFollowUpMutation();

  const [deleteFollowUp, { error: deleteError }] = useDeleteFollowUpMutation();
  const [updateFollowUp] = usePublishFollowUpMutation();

  useEffect(() => {
    dispatch(fetchFollowUps());
  }, []);

  const createFollowUp = async (e) => {
    e.preventDefault();
    const followUpData = {
      name: modalNameValue,
      account_id: user.id,
    };
    try {
      await addFollowUp(followUpData).unwrap();
      dispatch(fetchFollowUps());
      setModalNameValue("");
    } catch (error) {
      console.error("Failed to create follow-up:", error);
    }
  };

  const handleDeleteFollowUp = async () => {
    if (!followUpToDelete) return;
    try {
      await deleteFollowUp({
        id: followUpToDelete.id,
        account_id: followUpToDelete.account_id,
      }).unwrap();
      dispatch(fetchFollowUps());
      closePopup();
    } catch (error) {
      console.error("Failed to delete follow-up:", error);
    }
  };

  const handlePublishFollowUp = async () => {
    if (!followUpToPublish) return;
    const publishedFollowUp = {
      ...followUpToPublish,
      id: followUpToPublish.id,
      status: "live",
    };
    const unpublishedFollowUp = {
      ...followUpToPublish,
      id: followUpToPublish.id,
      status: "draft",
    };
    try {
      if (followUpToPublish.status === "draft") {
        await updateFollowUp(publishedFollowUp).unwrap();
      }
      if (followUpToPublish.status === "live") {
        await updateFollowUp(unpublishedFollowUp).unwrap();
      }

      dispatch(fetchFollowUps());
      closePopup();
    } catch (error) {
      console.error("Failed to publish follow-up:", error);
    }
  };

  const onDeleteFollowUpClick = (followUp) => {
    setFollowUpToDelete(followUp);
    setPopupMessage(deleteFollowUpMessage);
    setPopupClassName("warning");
    setIsOpenPopup(true);
  };

  const onPublishFollowUpClick = (followUp) => {
    setFollowUpToPublish(followUp);
    setPopupMessage(
      followUp.status === "draft"
        ? publishFollowUpMessage
        : unpublishFollowUpMessage,
    );
    setPublishFollowTitleButton(
      followUp.status === "draft" ? "Publish Follow Up" : "Unpublish Follow Up",
    );
    setPopupClassName("warning");
    setIsOpenPopup(true);
  };

  const closePopup = () => {
    setPopupMessage("");
    setPopupClassName("");
    setIsOpenPopup(false);
  };

  const addFollowUpHelper = useMemo(
    () =>
      isErrorAddFollowUp ? (
        <span className={styles.addFollowUpError}>
          {errorAddFollowUp.data.error}
        </span>
      ) : null,
    [errorAddFollowUp, isErrorAddFollowUp],
  );

  return (
    <>
      <Typography variant="title" className={styles.title}>
        Follow Ups
      </Typography>
      <div className={styles.container}>
        {
          <>
            {isCreateRowShown ? (
              <div
                className={classNames(
                  styles.createFollowContainer,
                  isCreateRowShown ? "" : "hidden",
                )}
              >
                <div className={styles.createFollowHeaderWrapper}>
                  <h2 className={styles.createFollowHeader}>
                    Create Follow Up
                  </h2>
                  <Button
                    title="Hide this"
                    variant="contained"
                    className={styles.closeCreateRowButton}
                    onClick={() => setIsCreateRowShown(false)}
                  />
                </div>
                <form onSubmit={createFollowUp}>
                  <div className={styles.createFollowWrapper}>
                    <div className={styles.followUpNameField}>
                      <label htmlFor="name">Choose a name</label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name"
                        value={modalNameValue}
                        onChange={(e) => setModalNameValue(e.target.value)}
                      />
                      {addFollowUpHelper}
                      <Button
                        disabled={isAdding}
                        title="Save"
                        className={styles.createButtonSave}
                        type="submit"
                        variant="contained"
                      />
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <Button
                title="Create follow up"
                variant="contained"
                className={styles.openCreateRowButton}
                onClick={() => setIsCreateRowShown(true)}
              />
            )}
            <div
              className={classNames(styles.wrapper, {
                [styles.displayNone]: followUps.length === 0,
              })}
              style={
                isCreateRowShown
                  ? {
                      height: "65%",
                    }
                  : { height: "80%" }
              }
            >
              <div className={styles.followUpsTable}>
                <div className={styles.followUpsTableHeaders}>
                  <h3>Name</h3>
                  <h3>Status</h3>
                  <h3>Type</h3>
                  <h3>Executed</h3>
                  <h3>Targeting</h3>
                </div>
                <div className={styles.followUpsList}>
                  <ul>
                    {followUps &&
                      followUps.map((followUp) => (
                        <FollowUpsListItems
                          key={followUp.id}
                          followUp={followUp}
                          onDeleteFollowUpClick={() => {
                            onDeleteFollowUpClick(followUp);
                          }}
                          onPublishFollowUpClick={() => {
                            onPublishFollowUpClick(followUp);
                          }}
                        />
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </>
        }
        {deleteError && (
          <p style={{ color: "red" }}>
            Error: {deleteError?.message || "Something went wrong"}
          </p>
        )}

        <ControlledPopup
          className={popupClassName}
          message={popupMessage}
          isOpenPopup={isOpenPopup}
          closePopup={closePopup}
          actions={
            popupMessage === deleteFollowUpMessage ? (
              <Button
                onClick={handleDeleteFollowUp}
                variant="contained"
                title="Delete Follow Up"
                color="edit"
              />
            ) : (
              <Button
                onClick={handlePublishFollowUp}
                variant="contained"
                color="edit"
                title={publishFollowTitleButton}
              />
            )
          }
        />
      </div>
    </>
  );
};
