import React from 'react';

import {useSelector} from "react-redux";

import {Title} from "../Title";
import {Button} from "../Button";

import styles from './Subscription.module.scss'
import {openInNewTab} from "../../utils";
import { current } from '@reduxjs/toolkit';


function formatDate(isoDateString) {
    // Parse the ISO date string into a JavaScript Date object
    const date = new Date(isoDateString);
  
    // Define an array of month names
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  
    // Get the month and day
    const month = monthNames[date.getUTCMonth()];
    const day = date.getUTCDate();
  
    // Create and return the formatted string
    return `${month} ${day}`;
}

export const Subscription = ({
                                 title,
                                 description,
                                 price,
                                 buttonTitle,
                                 messages,
                                 support,
                                 channels,
                                 integrations,
                                 currentSubscription,
                                 cancelPlan,
                                 upgradePlan,
                                 subscriptionIndex,
                                 buttonURL,
                             }) => {

    const user = useSelector(state => state.auth.user);

    return (
        <div className={styles.container}>
            <div>
                <Title title={title} className={title === 'Professional' ? styles.proTitle : ''}/>
                <div className={styles.description}>{description}
                    {title === 'Beginner' &&
                        <span className={styles.planDescription}> Start with 7 days free trial.</span>}
                    {title === 'Standard' && <span className={styles.planDescription}> Popular!</span>}
                </div>
            </div>
            <div>
                <div className={styles.price}>
                    <Title title={`$ ${price}`}/>
                    <div className={styles.perMonth}>per month</div>
                </div>
                <div>
                    <div className={styles.option}>
                        <div className={styles.option__title}>AI massages</div>
                        <div className={styles.option__description}>{messages}/month</div>
                    </div>
                    <div className={styles.option}>
                        <div className={styles.option__title}>Channels</div>
                        <div className={styles.option__description}>{channels}</div>
                    </div>
                    <div className={styles.option}>
                        <div className={styles.option__title}>Integrations</div>
                        <div className={styles.option__description}>{integrations}</div>
                    </div>
                    <div className={styles.option}>
                        <div className={styles.option__title}>Support</div>
                        <div
                            className={title === 'Professional' ? styles.option__proDescription : styles.option__description}>{support}</div>
                    </div>
                </div>
                    {!currentSubscription && <Button
                        onClick={() => window.location.href = buttonURL }
                        className={styles.button}
                        title={buttonTitle}
                    />}
                    {currentSubscription && currentSubscription.plan === subscriptionIndex && currentSubscription.ends_at === null && !currentSubscription.cancel_requested && <Button
                        onClick={() => cancelPlan() }
                        className={styles.button}
                        title={"Cancel Plan"}
                    />}
                    {currentSubscription && currentSubscription.plan === subscriptionIndex && currentSubscription.ends_at === null && currentSubscription.cancel_requested && <Button
                        onClick={() => cancelPlan() }
                        className={styles.button}
                        disabled
                        style={{background: "#d9d9db", color: "#384157"}}
                        tooltip={"We will cancel your subscription during 24 hours"}
                        title={"Cancel requested"}
                    />}
                    {currentSubscription && currentSubscription.plan !== subscriptionIndex && currentSubscription.ends_at === null && currentSubscription.cancel_requested && <Button
                        onClick={() => cancelPlan() }
                        className={styles.button}
                        disabled
                        style={{background: "#d9d9db", color: "#384157"}}
                        tooltip={"You will be able to upgrade soon"}
                        title={"Switch to this plan"}
                    />}
                    {currentSubscription && currentSubscription.plan === subscriptionIndex && currentSubscription.ends_at !== null && !currentSubscription.cancel_requested && <Button
                        className={styles.button}
                        style={{background: "#d9d9db", color: "#384157"}}
                        disabled
                        title={"Ends at " + formatDate(currentSubscription.ends_at)}
                    />}
                    {currentSubscription && currentSubscription.plan !== subscriptionIndex && currentSubscription.ends_at !== null && !currentSubscription.cancel_requested && currentSubscription.upgrade_requested == subscriptionIndex && <Button
                        disabled
                        className={styles.button}
                        style={{background: "#d9d9db", color: "#384157"}}
                        title={"Upgrade requested"}
                        tooltip={"You will receive payment link over the email"}
                    />}
                    {currentSubscription && currentSubscription.plan !== subscriptionIndex && currentSubscription.ends_at !== null && !currentSubscription.cancel_requested && currentSubscription.upgrade_requested && currentSubscription.upgrade_requested !== subscriptionIndex && <Button
                        disabled
                        className={styles.button}
                        style={{background: "#d9d9db", color: "#384157"}}
                        title={"Switch to this plan"}
                        tooltip={"You already requested plan upgrade"}
                    />}
                    {currentSubscription && currentSubscription.plan !== subscriptionIndex && currentSubscription.ends_at === null && !currentSubscription.cancel_requested && currentSubscription.upgrade_requested && currentSubscription.upgrade_requested !== subscriptionIndex && <Button
                        className={styles.button}
                        disabled
                        style={{background: "#d9d9db", color: "#384157"}}
                        title={"Switch to this plan"}
                        tooltip={"You need to request cancellation of your current plan first"}
                    />}
                    {currentSubscription && currentSubscription.plan !== subscriptionIndex && currentSubscription.ends_at === null && !currentSubscription.cancel_requested && !currentSubscription.upgrade_requested && <Button
                        className={styles.button}
                        disabled
                        style={{background: "#d9d9db", color: "#384157"}}
                        title={"Switch to this plan"}
                        tooltip={"You need to request cancellation of your current plan first"}
                    />}
                    {currentSubscription && currentSubscription.plan !== subscriptionIndex && currentSubscription.ends_at !== null && !currentSubscription.cancel_requested && !currentSubscription.upgrade_requested && <Button
                        onClick={() => upgradePlan(subscriptionIndex)}
                        className={styles.button}
                        title={"Switch to this plan"}
                        tooltip={"You can request change of the plan, we will send the upgrade link to your email"}
                    />}
            </div>
        </div>
    );
};
