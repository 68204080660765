import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { toast } from "react-toastify";

import { setOnboarding } from "../../store/slices/onboardingSlice";
import { useOptimizeMutation } from "../../store/api";
import {
  getData,
  updateData,
  addData,
  deleteData,
} from "../../service/supabase.js";
import { selectUser } from "../../store/slices/authSlice";
import {
  getActiveAssistant,
  fetchInstructions,
  setSelectedAssistant,
  _updateListOfAssistants,
} from "../../store/slices/assistantSlice";

import { Title } from "../../components/Title";
import { Button } from "../../components/Button";
import { Input, Loader } from "../../components";
import { Bot } from "../../components/Bot";
import { FormForAddingNot } from "../../components/popups/FormForAddingNot";
import { PickADay } from "../../components/popups/PickADay";
import { AssistantToolkit } from "./components/AssistantToolkit";
import { AssistantFollowUps } from "./components";
import { SetAssistantMetadataModal } from "../../components/popups/SetAssistantMetadataModal/SetAssistantMetadataModal";

import "react-toastify/dist/ReactToastify.css";
import styles from "./AIConfiguration.module.scss";
import "./fixStyle.css";

import {
  COMMUNICATION_STYLE_EN,
  COMMUNICATION_STYLE_RU,
  LANGUAGE,
} from "../../constants/configuration";
import { Modal } from "../../components/Modal";
import { createABot, createAssistant } from "../../helper/create-data.js";

export const AIConfigurationPage = () => {
  const windowWidth = window.innerWidth;

  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const step = useSelector((state) => state.onboarding.step);

  const listOfAssistants = useSelector(
    (state) => state.assistant.listOfAssistants
  );
  const selectedAssistant = useSelector(
    (state) => state.assistant.selectedAssistant
  );
  const activeAssistant = useSelector(
    (state) => state.assistant.activeAssistant
  );

  const updateListOfAssistants = useSelector(_updateListOfAssistants);

  const [botName, setBotName] = useState("");
  const [isFormForAddingNot, setFormForAddingNot] = useState(false);
  const [knowledgeBase, setKnowledgeBase] = useState(null);
  const [isPickADay, setPickADay] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({
    value: 1,
    label: "English",
  });
  const [selectedCommunicationStyle, setSelectedCommunicationStyle] =
    useState("");
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [roleAndInstructions, setRoleAndInstructions] = useState("");
  const [optimize, setOptimize] = useState(false);
  const [optimizeRequest, setOptimizeRequest] = useState(false);
  const [changeProfile, setChangeProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState({
    botName: "",
    roleAndInstructions: "",
    selectedLanguage: { value: 1, label: "English" },
    selectedCommunicationStyle: {},
    welcomeMessage: "",
    followUps: [],
    metadata: {},
  });
  const [selectedFollowUps, setSelectedFollowUps] = useState([]);
  const [changeData, setChangeData] = useState(true);
  const [isShowModal, setIsShowModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [assistantId, setAssistantId] = useState("");
  const [checked, setChecked] = useState(false);
  const [isShowMetadataModal, setIsShowMetadataModal] = useState(false);
  const [metadata, setMetadata] = useState({});

  const [createOptimize] = useOptimizeMutation();

  // const isRoleAndInstructions = useMemo(
  //   () => roleAndInstructions !== originalData?.roleAndInstructions,
  //   [roleAndInstructions, originalData]
  // );

  const items = [
    botName,
    selectedLanguage,
    selectedCommunicationStyle,
    welcomeMessage,
    roleAndInstructions,
    knowledgeBase,
    selectedFollowUps,
  ];

  const disabled = useMemo(() => {
    return items.some((el) => !el);
  }, [items]);

  const getCommunicationData = (language, val = 1) => {
    return language === val ? COMMUNICATION_STYLE_EN : COMMUNICATION_STYLE_RU;
  };

  const updateDate = (val1 = false, val2 = false, bot = {}) => {
    setOptimize(val1);
    setChangeData(val2);
    setOriginalData(bot);
  };

  const cloneAssistant = async () => {
    if (!user?.id) return;
    try {
      const newBotName = botName
        ? botName + " " + (listOfAssistants.length + 1)
        : "Вероніка";
      const { ...other } = activeAssistant || {};
      const cloneData = { ...other, bot_name: newBotName, id: undefined };

      await addData("assistants", cloneData);
      setBotName(newBotName);
      dispatch(fetchInstructions(user.id));
      toast.success(
        `${newBotName} has been cloned from ${botName}, now edit and save it`
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleOptimize = async () => {
    try {
      setLoading(true);
      setOptimizeRequest(true);
      const { error: errorOptimize, data } = await createOptimize({
        account_id: user.id,
        lang: selectedLanguage.value,
        prompt: roleAndInstructions,
      });

      if (errorOptimize) {
        setIsShowModal(true);
        setErrorText(errorOptimize.data);
      }

      setOptimizeRequest(false);

      setOptimize(true);
      setRoleAndInstructions(data.prompt);
      setChangeProfile(false);
    } catch (error) {
      setOptimizeRequest(false);
      setOptimize(false);
      setIsShowModal(true);
      setErrorText("Failed to optimize, try again");
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!user?.id) return;
    try {
      const { data: instruction } = await getData(
        "assistants",
        "*",
        "account_id",
        user.id
      );

      const assistant = createAssistant(
        assistantId,
        user.id,
        botName,
        selectedLanguage.value,
        selectedCommunicationStyle.value,
        welcomeMessage,
        roleAndInstructions,
        knowledgeBase,
        selectedFollowUps
      );

      const bot = createABot(
        botName,
        roleAndInstructions,
        selectedLanguage,
        selectedCommunicationStyle,
        welcomeMessage,
        selectedFollowUps
      );

      updateDate(false, false, bot);

      if (instruction.length) {
        await addData("assistants", [assistant]);
        dispatch(fetchInstructions(user.id));
        toast.success(`Assistant ${botName} was saved!`);
      }

      await updateData("assistants", assistant, { account_id: user.id });
      await updateData("onboardings", { step: 6 }, { account_id: user.id });
      dispatch(fetchInstructions(user.id));
      dispatch(setOnboarding(6));
    } catch (e) {
      toast.error(e.message);
    }
  };

  //const handleDeleteAssistant = async () => {
  //  try {
  //    await deleteData("assistants", {
  //      id: selectedAssistant.value,
  //      account_id: user.id,
  //    });
  //    dispatch(fetchInstructions(user.id));
  //    toast.success(`Assistant ${botName} was deleted!`);
  //  } catch (e) {
  //    toast.error(e.message);
  //  }
  //};

  const handleChange = async () => {
    if (!user?.id) return;
    try {
      const status = !checked ? 1 : 0;
      await updateData(
        "assistants",
        { status },
        { id: selectedAssistant.value }
      );
      setChecked(!checked);
      dispatch(fetchInstructions(user.id));
    } catch ({ message }) {
      toast.error(message);
    }
  };

  useEffect(() => {
    if (!selectedAssistant.value || !listOfAssistants.length) return;

    const foundAssistant = listOfAssistants.find(
      ({ id }) => id === selectedAssistant.value
    );

    if (!foundAssistant) {
      console.log("No matching assistant found");
      return; // Early return if no assistant is found
    }

    const {
      id,
      bot_name,
      language,
      communication_style,
      welcome_message,
      instructions,
      knowledge_base,
      follow_ups,
      metadata: assistantMetadata,
      ...other
    } = foundAssistant;
    setAssistantId(id);

    dispatch(
      getActiveAssistant({
        id,
        bot_name,
        language,
        communication_style,
        welcome_message,
        instructions,
        knowledge_base,
        follow_ups,
        ...other,
      })
    );
    setKnowledgeBase(knowledge_base);
    const selectedLanguage = LANGUAGE.find(({ value }) => value === language);
    const selectedCommunicationStyle = getCommunicationData(language).find(
      ({ value }) => value === communication_style
    );

    setBotName(bot_name);
    setSelectedLanguage(selectedLanguage);
    setSelectedCommunicationStyle(selectedCommunicationStyle);
    setWelcomeMessage(welcome_message);
    setRoleAndInstructions(instructions);
    setSelectedFollowUps(follow_ups ? follow_ups : []);

    setChecked(selectedAssistant.status === 1);

    setMetadata(assistantMetadata || {});

    setOriginalData(
      createABot(
        bot_name,
        instructions,
        selectedLanguage,
        selectedCommunicationStyle,
        welcome_message,
        follow_ups ? follow_ups : []
      )
    );
  }, [selectedAssistant, listOfAssistants]);

  useEffect(() => {
    if (isFormForAddingNot) {
      setPickADay(false);
      return;
    }
  }, [isFormForAddingNot]);

  useEffect(() => {
    if (isPickADay) {
      setFormForAddingNot(false);
    }
  }, [isPickADay]);

  useEffect(() => {
    const values = Object.values(originalData);

    if (!values.every((el) => !!el)) return;

    const {
      botName: bN,
      roleAndInstructions: role,
      selectedLanguage: select,
      welcomeMessage: wM,
      selectedCommunicationStyle: style,
      followUps,
    } = originalData;

    setChangeData(
      !(
        bN !== botName ||
        role !== roleAndInstructions ||
        select.value !== selectedLanguage.value ||
        wM !== welcomeMessage ||
        style.value !== selectedCommunicationStyle.value ||
        JSON.stringify(followUps) !== JSON.stringify(selectedFollowUps)
      )
    );
  }, [
    botName,
    roleAndInstructions,
    selectedLanguage,
    welcomeMessage,
    selectedCommunicationStyle,
    originalData,
    selectedFollowUps,
  ]);

  useEffect(() => {
    if (!user?.id) return;
    dispatch(fetchInstructions(user.id));
  }, [user]);

  useEffect(() => {
    if (!listOfAssistants.length) return;
    let item = null;
    if (!botName) {
      item = listOfAssistants[0];
    } else {
      item = listOfAssistants.find(({ bot_name }) => bot_name === botName);
      if (!item) item = listOfAssistants[0];
    }

    dispatch(
      setSelectedAssistant({
        value: item.id,
        label: item.bot_name,
        status: item.status,
      })
    );

    setChecked(item.status === 1);
  }, [listOfAssistants]);

  return (
    <div
      className={styles.container}
      style={{ height: windowWidth >= 666 ? (step === 6 ? "100vh" : "") : "" }}
    >
      <div
        className={styles.assistantProfile}
        style={{
          height:
            windowWidth >= 666 ? (step === 6 ? "" : "calc(100vh - 18rem)") : "",
          maxHeight:
            windowWidth >= 666 ? (step === 6 ? "" : "calc(100vh - 18rem)") : "",
        }}
      >
        <div className={styles.rowAssistant}>
          <div className={styles.wTitle}>
            <Title title={"Assistant Configuration"} />
          </div>
          <Switch
            onChange={handleChange}
            checked={checked}
            uncheckedIcon={false}
            checkedIcon={false}
            height={27}
            offColor="#FF0000"
            onColor="#19CE36"
          />
          <div className={`${styles.rowEl} row`}>
            <div className="select-assistant">
              <Input
                typeInput={"select"}
                placeholder={"Select an assistant"}
                options={updateListOfAssistants}
                value={selectedAssistant}
                onChange={(selectedOption) =>
                  dispatch(setSelectedAssistant(selectedOption))
                }
              />
            </div>
            <button
              type="button"
              className={`${styles.saveOptimize} add-new`}
              onClick={cloneAssistant}
            >
              add new
            </button>
          </div>
        </div>
        <div className={styles.description}>
          In this section, you're given keys to customize your AI assistant to
          best represent your business and process your clients. If you are
          curious,{" "}
          <a href="https://mychatbotapp.notion.site/Fundamentals-bd181032e5934f528b59e7ac69072c9c">
            here
          </a>{" "}
          is a short read on fundamentals.
        </div>
        <h2 className={styles.titleInputs}>Assistant profile</h2>
        <Input
          typeInput={"aiinput"}
          inputTitle={"Name"}
          placeholder={"Name"}
          value={botName}
          onChange={(e) => setBotName(e.target.value)}
        />
        <div className="mb-1_5">
          <Input
            typeInput={"select"}
            placeholder={"Communication style"}
            options={
              selectedLanguage.label === "English"
                ? COMMUNICATION_STYLE_EN
                : COMMUNICATION_STYLE_RU
            }
            inputTitle={"Communication style"}
            value={selectedCommunicationStyle}
            onChange={(selectedOption) =>
              setSelectedCommunicationStyle(selectedOption)
            }
          />
        </div>
        <Input
          typeInput={"textarea"}
          maxlength={150}
          inputTitle={"Welcome message"}
          placeholder={
            selectedLanguage.label === "English"
              ? "Greetings! How can we make your day better? 😊"
              : "Привет! Как я могу сделать ваш день лучше? 😊"
          }
          value={welcomeMessage}
          onChange={(e) => setWelcomeMessage(e.target.value)}
        />
        <div className="mb-1_5">
          <AssistantToolkit
            userId={user?.id}
            knowledgeBase={knowledgeBase}
            key={knowledgeBase + user?.id}
            setKnowledgeBase={(value) => {
              setKnowledgeBase((prev) => {
                setChangeData(prev === value);
                return value;
              });
            }}
          />
        </div>
        <div className="mb-1_5">
          <AssistantFollowUps
            selectedFollowUps={selectedFollowUps}
            setSelectedFollowUps={setSelectedFollowUps}
          />
        </div>
        <div className={styles.btnBlock}>
          <button
            type="button"
            className={styles.saveOptimize}
            onClick={() => setPickADay(true)}
          >
            Set Bot Time
          </button>
          <button
            type="button"
            className={styles.saveOptimize}
            onClick={() => setFormForAddingNot(true)}
          >
            Set Need Operator
          </button>
          <button
            type="button"
            className={styles.saveOptimize}
            onClick={() => setIsShowMetadataModal(true)}
          >
            Switch to Operator
          </button>
        </div>

        <FormForAddingNot
          setFormForAddingNot={setFormForAddingNot}
          assistantId={selectedAssistant?.value}
          selectedAssistant={selectedAssistant}
          isFormForAddingNot={isFormForAddingNot}
          userId={user?.id}
        />

        <PickADay
          key={selectedAssistant?.value}
          setPickADay={setPickADay}
          assistantId={selectedAssistant?.value}
          userId={user?.id}
          isPickADay={isPickADay}
        />

        <SetAssistantMetadataModal
          isOpen={isShowMetadataModal}
          onClose={() => setIsShowMetadataModal(false)}
          metadata={metadata}
          assistantId={assistantId}
        />
        <h2 className={styles.titleBot}>Assistant instructions</h2>
        <div className={styles.description}>
          In this section you give instructions to your assistant, so it knows
          the purpose of customer interaction and what action it is expected to
          do. Answering questions is assumed by default in all options.
        </div>

        <div>
          {loading ? (
            <div>
              <div className={styles.titleInput}>Role and instructions</div>
              <div className={styles.loaderContainer}>
                <Loader height={40} width={40} />
              </div>
            </div>
          ) : (
            <Input
              typeInput={"textarea"}
              maxlength={10000}
              inputTitle={"Role and instructions"}
              placeholder={"Role and instructions"}
              value={roleAndInstructions}
              onChange={(e) => setRoleAndInstructions(e.target.value)}
            />
          )}
        </div>

        <div className={styles.buttons}>
          {/*<Button*/}
          {/*      onClick={handleOptimize}*/}
          {/*  className={`${styles.saveOptimize} ${*/}
          {/*    !optimizeRequest*/}
          {/*      ? disabled*/}
          {/*        ? styles.disabled*/}
          {/*        : ""*/}
          {/*      : styles.disabled*/}
          {/*  }`}*/}
          {/*  title={"OPTIMIZE INSTRUCTIONS"}*/}
          {/*  disabled={!optimizeRequest ? disabled : true}*/}
          {/*/>*/}
          {/*<Button
            title="Remove assistant"
            variant="contained"
            color="error"
            onClick={handleDeleteAssistant}
            type="button"
          />*/}
          <Button
            onClick={handleSave}
            className={`${styles.saveOptimize} ${
              changeData || changeProfile || disabled
            }`}
            title={"SAVE ASSISTANT"}
            disabled={
              changeData || disabled || changeProfile
              // || optimizeRequest || (isRoleAndInstructions && !optimize)
            }
          />
        </div>
      </div>
      <Bot assistantId={assistantId} />
      <Modal
        isShowModal={isShowModal}
        title={"Error"}
        description={errorText}
        setIsShowModal={setIsShowModal}
      />
    </div>
  );
};
